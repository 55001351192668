import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { AddHopDong } from "./models/KhamDoanModel";
import { AppContext } from "../appContext/AppContext";
import { FC, useContext, useEffect, useState } from "react";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  MAX_PAGE_SIZE,
  RESPONSE_MESSAGE,
} from "../utils/Constant";
import {
  getByIdHopDong,
  getDanhSachHinhThucService,
} from "./services/KhamDoanHopDongServices";
import moment from "moment";
import TextField from "../component/TextField";
import LabelRequired from "../component/LabelRequired";
import AutocompleteObjectV2 from "../component/AutocompleteObjectV2";
import NumberField from "../component/number-field";

type Props = {
  thongTinHD: any;
  touched: any;
  errors: any;
};

const FormThemMoiHopDong: FC<Props> = (props) => {
  const { thongTinHD, touched, errors } = props;
  const [dsHinhThuc, setDanhSachHinhThuc] = useState<any>([]);
  const { setIsLoading } = useContext(AppContext);
  const { setValues, setFieldValue, values } = useFormikContext<AddHopDong>();

  useEffect(() => {
    getDanhSachHinhThuc();
  }, []);
  useEffect(() => {
    thongTinHD?.id && handlerEditHopDong(thongTinHD);
  }, [thongTinHD?.id]);
  const handlerEditHopDong = async (value: any) => {
    try {
      const { data } = await getByIdHopDong(value?.id);
      if (CODE.SUCCESS === data?.code) {
        const resultData = data?.data;
        resultData.paymentMethod = {
          id: data?.data?.paymentMethodId,
          name: data?.data?.paymentMethodName,
        };
        setValues(resultData);
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeVisit = (name: string, value: any) => {
    setFieldValue(name, value);
  };
  const getDanhSachHinhThuc = async () => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: MAX_PAGE_SIZE,
      type: 21,
    };
    try {
      const { data } = await getDanhSachHinhThucService(searchObject);

      if (CODE.SUCCESS === data?.code) {
        setDanhSachHinhThuc(data?.data?.content || []);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex">
      <div className=" border-right-0 border-left-0 border-top-0 py-2 spaces pe-2">
        <Row className="mx-0 pl-0">
          <Col xs="6" className=" mb-5 mt-5">
            <TextField
              label={
                <div>
                  Tên hợp đồng
                  <span className="text-danger">(*)</span>
                </div>
              }
              name="contractName"
              labelClassName="min-w-130px"
            />
          </Col>
          <Col xs="6" className="mb-5 mt-5">
            <TextField
              label={
                <div>
                  Tên công ty
                  <span className="text-danger">(*)</span>
                </div>
              }
              name="companyName"
              labelClassName=" min-w-110px"
            />
          </Col>
          <Col xs="6" className=" mb-5">
            <TextField
              label={<div>Địa chỉ công ty</div>}
              name="address"
              labelClassName=" min-w-130px "
            />
          </Col>
          <Col xs="3" className="mb-5">
            <TextField
              label={
                <div>
                  Mã số thuế
                  <span className="text-danger">(*)</span>
                </div>
              }
              name="taxNumber"
              labelClassName=" min-w-110px"
            />
          </Col>
          <Col xs="3" className=" mb-5">
            <TextField
              label={<div>Người giới thiệu</div>}
              name="referenceName"
              labelClassName=" min-w-110px "
            />
          </Col>
          <Col xs="3" className="mb-5">
            <TextField
              label={<div>Người liên lạc</div>}
              name="contactPerson"
              labelClassName=" min-w-130px"
            />
          </Col>
          <Col xs="3" className=" mb-5 ">
            <TextField
              label={<div>Email liên hệ</div>}
              name="referenceEmail"
              labelClassName=" min-w-90px"
            />
          </Col>
          <Col xs="3" className="mb-5">
            <TextField
              label={
                <div>
                  Ngày bắt đầu
                  <span className="text-danger">(*)</span>
                </div>
              }
              name="startDate"
              type="date"
              labelClassName=" min-w-110px"
              className=""
              value={
                values?.startDate
                  ? moment(values?.startDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </Col>
          <Col xs="3" className=" mb-5">
            <TextField
              label={
                <div>
                  Ngày kết thúc
                  <span className="text-danger">(*)</span>
                </div>
              }
              name="endDate"
              type="date"
              labelClassName="min-w-110px"
              value={
                values?.endDate
                  ? moment(values?.endDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </Col>

          <Col xs="6">
            <Row className="ml-0">
              <Col xs="6" className="mb-5 ml-0">
                <NumberField
                  label={
                    <div>
                      Giá trị hợp đồng
                    </div>
                  }
                  name="contractValue"
                  labelClassName="min-w-130px"
                />
              </Col>
              <Col xs="6" className="flex mb-5">
                <LabelRequired
                  isRequired
                  className="min-w-130px"
                  label="Hình thức thu"
                />
                <AutocompleteObjectV2
                  options={dsHinhThuc || []}
                  name="paymentMethod"
                  value={values?.paymentMethod || ""}
                  onChange={(selectedOption) =>
                    handleChangeVisit("paymentMethod", selectedOption)
                  }
                  isClearable={false}
                  className="spaces autocomplete-custom-tiep-nhan h-29 ml-6"
                  touched={touched?.paymentMethod}
                  errors={errors?.paymentMethod}
                />
              </Col>
            </Row>
            <Col xs="12" className="px-0 mx-2 mb-5">
              <Form.Check
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Hợp đồng ưu tiên"}
                name={"priority"}
                onChange={() => {}}
              />
            </Col>
          </Col>

          <Col xs="6" className="mb-5">
            <TextField
              label={<div className="mb-10">Ghi chú</div>}
              name="note"
              labelClassName="min-w-110px"
              className="min-height-60px"
              as="textarea"
              value={values?.note || ""}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default FormThemMoiHopDong;
