import { Formik, FormikHelpers } from "formik";
import { FC, createContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import './PhanHeKhamDoan.scss';
import { initialValuesKhamDoan } from "./constants/PhanHeKhamDoan";
import { IKhamDoan } from "./models/KhamDoanModel";
import { Form } from "react-bootstrap";
import ThongTinHopDong from "./components/ThongTinHopDong/ThongTinHopDong"

export interface PhanHeKhamDoanContextProps {
    thongTinBenhNhan: IKhamDoan;
    setThongTinBenhNhan: (value: IKhamDoan) => void;
    handleAddTab: (eventKey: string) => void;
    workFlowStatus: any;
    setWorkFlowStatus: any;
}

 const initialContext = {
    thongTinBenhNhan: {} as IKhamDoan,
    setThongTinBenhNhan: () => { },
    handleAddTab: () => { },
    workFlowStatus: {},
    setWorkFlowStatus: () => { },
}

export const PhanHeKhamDoanContext = createContext<PhanHeKhamDoanContextProps>(initialContext)

export const PhanHeKhamDoan: FC = () => {
    const [activeTab, setActiveTab] = useState("")
    let [objectSearch, setObjectSearch] = useState<any>();
    const [thongTinBenhNhan, setThongTinBenhNhan] = useState<IKhamDoan>(initialValuesKhamDoan);
    const [workFlowStatus, setWorkFlowStatus] = useState<any>("");
    const intl = useIntl();
    useEffect(() => {
    }, []);

    const handleAddTab = (eventKey: string) => {
    }

    const handleFormSubmit = async (values: IKhamDoan, { setValues }: FormikHelpers<IKhamDoan>) => {
     
    }

    return (
        <Formik<IKhamDoan>
            initialValues={{ ...thongTinBenhNhan }}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <PhanHeKhamDoanContext.Provider
                        value={{
                            thongTinBenhNhan, setThongTinBenhNhan,
                            handleAddTab,
                            setWorkFlowStatus,
                            workFlowStatus
                        }}
                    >
                        <div className="pe-list bg-white">

                            <div className="flex-1 flex flex-column">
                                <div className="pt-3 h-100 px-3">
                                    <ThongTinHopDong
                                        setActiveTab={setActiveTab}
                                    />
                                    {/* )} */}
                                </div>
                            </div>


                        </div>

                    </PhanHeKhamDoanContext.Provider>
                </Form>
            )}
        </Formik >
    )
}

export default PhanHeKhamDoan;
