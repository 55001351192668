import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import { getDetailOrderKhamBenh, saveKhamBenh } from "../services/KhamLamSangSevice";
import FormKhamMat from "./FormKhamMat";
import "./KhamLamSang.scss";

type Props = {
  // selectedRow: any;
};
const initialValues: any = {
  LEUncorrected: "",
  LRWithGlasses: "",
  LRUncorrected: "",
  LEWithGlasses: "",
  otherEyeConditions: "",
  eyeClassification: "",
  eyeConclusion: "",
};
const TabKhamMat = ({ }: Props) => {


  const { setWorkFlowStatus, workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext)
  const { TTNhanVien, TTDetailKhamBenh } = useContext<KhamLamSangContextProps>(KhamLamSangContext)
  const { setIsLoading } = useContext(AppContext);
  const [isView, setIsView] = useState<any>(false);
  const [content, setContent] = useState<any>();

  useEffect(() => {
    TTNhanVien?.visit?.id && TTNhanVien?.orderId && getDetailNV();
  }, [TTNhanVien?.visit?.id && TTNhanVien?.orderId]);

  const getDetailNV = async () => {
    try {
      setIsLoading(true);
      let { data } = await getDetailOrderKhamBenh({
        visitId: TTNhanVien?.visit?.id,
        orderId: TTNhanVien?.orderId
      });

      if (CODE.SUCCESS === data?.code) {
        setContent(data?.data?.obs);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlerSave = async (values: any) => {
    let dataValue = {
      ...values,
    }
    try {
      setIsLoading(true);
      let { data } = await saveKhamBenh(dataValue, "khamMat", TTNhanVien?.encounter?.id);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

  }
  useEffect(() => {
    handlerCheckDisable()
  }, [TTNhanVien?.encounter?.id,workFlowStatus?.currStatusCode])

  const handlerCheckDisable = () => {
    if (TTNhanVien?.encounter?.id && workFlowStatus?.currStatusCode !== WF_STATUS.KET_THUC_KHAM.CODE) { setIsView(false) } else {
      setIsView(true)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handlerSave}>
      {({
        values,
        setValues,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Form id="form-kham-mat" onSubmit={handleSubmit}>
          <div className="bg-white  modelKhamBenh spaces h-calc-vh-340 mb-6 fl-1">
            <FormKhamMat setValues={setValues} isView={isView} values={values} content={content} />
            <div className="flex bg-white spaces border-top justify-content-end gap-6 py-10 pr-10 mt-0  btn-luu">
              {!isView &&
                <Button className="btn-fill spaces min-w-86" onClick={() => handlerSave(values)}>
                  <span>Lưu lại</span>
                </Button>
              }
            </div>
            {/* </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TabKhamMat;
