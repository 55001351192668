import LabelRequired from "../../component/LabelRequired";
import { convertNumberPrice } from "../../utils/FormatUtils";

type Props = {
  paymentData?: any;
  children?: any;
  className?: string;
};

const PaymentInfor = ({
  paymentData = [],
  children = "",
  className = "",
}: Props) => {
  return (
    <div className={`${className} spaces my-5 ml-10`}>
      <div className="price-info-box spaces mr-50">
        {paymentData?.map((item: any, index: number) => {
          return (
            <div className="d-flex align-items-center" key={index}>
              <LabelRequired
                label={item?.name}
                className={`${item?.color} fw-bold`}
              />
              <div
                className={`${
                  item?.color ? ` ${item?.color} fw-bold` : ""
                }  spaces py-2 px-8`}
              >
                {item?.type === "price"
                  ? convertNumberPrice(item?.value)
                  : item?.value || ""}
              </div>
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};
export default PaymentInfor;
