import { useContext, useEffect, useState } from "react";
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML";
import { KhamLamSangContext } from "../KhamLamSang";
import { createSoKham, getDetailSoKham } from "../services/KhamLamSangSevice";
import { CODE, FORM_CODE } from "../../../../utils/Constant";
import { toast } from "react-toastify";

const SoKhamSucKhoe = (props: any) => {
  const { setIsLoading } = useContext(AppContext);
  const { TTNhanVien, dataTienSuBanThan, setDataTienSuBanThan, isView, bmiData, setBMIData } =
    useContext(KhamLamSangContext);
  const [dataGenerate, setDataGenerate] = useState();
  const [dataSoKham, setDataSoKham] = useState({ values: {}, id: null });

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const newDataTienSuBanThan = dataTienSuBanThan?.filter((item: any) => 
        item.diseaseName ||
        item.discoveryYear || 
        item.occDiseaseName || 
        item.occDiscoveryYear
      );
      const dataSubmit = {
        staffId: TTNhanVien.id,
        values: {
          ...values.dataSend,
          weight: bmiData?.weight,
          height: bmiData?.height,
          bmi: bmiData?.bmi,
          classification: bmiData?.classification?.code
        },
        personalMedicals: newDataTienSuBanThan,
      };
      const res = await createSoKham({ ...dataSubmit, id: dataSoKham?.id })
      if (CODE.SUCCESS === res.status) {
        setDataTienSuBanThan(newDataTienSuBanThan);
        toast.success("Lưu thành công");
      } else {
        toast.error("Có lỗi xảy ra, vui lòng thử lại");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDataSoKham = async () => {
    try {
      setIsLoading(true);
      const res = await getDetailSoKham(TTNhanVien.id);
      if (CODE.SUCCESS === res.status) {
        setDataTienSuBanThan(res?.data?.data?.personalMedicals || []);
        setDataSoKham(res?.data?.data);
        setBMIData(res?.data?.data?.values);
      } else {
        toast.error("Có lỗi xảy ra, vui lòng thử lại");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (TTNhanVien.id) {
      updateDataSoKham();
    }
  }, [TTNhanVien.id]);

  const getForm = async () => {
    let data = await generateForm(FORM_CODE.SO_KHAM_SUC_KHOE, setIsLoading);
    setDataGenerate(data);
  };

  useEffect(() => {
    getForm();
    if (TTNhanVien.id) {
      updateDataSoKham();
    }
  }, []);

  return (
    <div
      id="container-so-kham-suc-khoe"
      className="scroll-y spaces h-calc-vh-180 d-flex justify-content-center"
    >
      {TTNhanVien.id ? (
        <GenerateFormHTML
          isView={isView}
          listFieldAuto={dataGenerate}
          handleSave={handleSubmit}
          itemEdit={dataSoKham?.values}
        />
      ) : (
        <div className="spaces py-10">Vui lòng chọn nhân viên</div>
      )}
    </div>
  );
};

export default SoKhamSucKhoe;
