import clsx from 'clsx';
import { useFormikContext } from 'formik';
import Fuse from 'fuse.js';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RowMouseEventHandlerParams } from 'react-virtualized';
import '../../../component/input-autocomplete/style.scss';
import SimpleTable from '../../../component/table/simple-table/SimpleTable';
import { RESPONSE_MESSAGE } from '../../../utils/Constant';
import { formatMoney } from '../../../utils/FormatUtils';
import { LOAI_VAT_TU } from '../../constants/VatTu';
import { IThuoc } from '../../models/ThuocModels';
import { searchVatTu } from '../../services/VatTuService';
import { COLUMNS } from './Column';

type Props = {
	value: string;
	maKhoThuoc: string;
	setThuocSelected: (value: IThuoc) => void;
	label?: string;
	containerClassName?: string;
	inputClassName?: string;
	labelClassName?: string;
};

const SelectTableButton = ({
	value,
	maKhoThuoc,
	setThuocSelected,
	containerClassName,
	inputClassName,
	labelClassName,
	label,
	...props
}: Props) => {
	const { setFieldValue, values } = useFormikContext<any>();

	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const initialTableDataRef = useRef<any[]>([]);
	const selectedRowRef = useRef<any>();

	const [tableData, setTableData] = useState<any[]>([]);
	const [isShow, setIsShow] = useState<boolean>(false);
	const [query, setQuery] = useState<string>('');

	useEffect(() => {
		window.addEventListener('click', checkMouseClickOutSide, true);

		return () => {
			window.removeEventListener('click', checkMouseClickOutSide, true);
		};
	}, []);

	useEffect(() => {
		setQuery(values?.tenThuoc);
		if(!values?.tenThuoc) selectedRowRef.current = null;
	}, [values?.tenThuoc]);

	useEffect(() => {
		if (maKhoThuoc && isShow) {
			getDanhSachThuoc();
		}
	}, [maKhoThuoc, isShow]);

	const checkMouseClickOutSide = (e: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
			setIsShow(false);
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		setQuery(inputValue);

		if (!inputValue) {
			setTableData(initialTableDataRef.current);
			return;
		}

		const fuse = new Fuse(initialTableDataRef.current, {
			keys: ['code', 'name', 'actIngName', 'dosage'],
			threshold: 0.8,
			includeScore: true,
		});
		const result = fuse.search(inputValue).map(record => record.item);
		setTableData(result);
	};

	const getDanhSachThuoc = async () => {
		try {
			const response = await searchVatTu({
				warehouseId: maKhoThuoc,
				materialGroup: LOAI_VAT_TU.THUOC,
				pageIndex: 1,
				pageSize: 100_000,
			});
			setTableData(response);
			initialTableDataRef.current = response;
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onRowSelect = (rowData: any) => {
		if (!rowData) return;
		setFieldValue('maThuoc', rowData.code);
		setFieldValue('tenThuoc', rowData.name);
		selectedRowRef.current = rowData;
		setThuocSelected({
			...rowData,
			roa: rowData.ROAName,
		});
		setIsShow(false);
	};

	const renderTable = () => {
		const onRenderCell = (columnData: any, dataKey: string, rowData: any) => {
			rowData = {
				...rowData,
				BHYTPrice: formatMoney(rowData?.BHYTPrice) || 0,
				hospitalFeePrice: formatMoney(rowData?.hospitalFeePrice) || 0,
				requestedPrice: formatMoney(rowData?.requestedPrice) || 0,
			};

			return rowData[dataKey];
		};

		return (
			<Popover
				style={{
					minWidth: inputRef.current?.offsetWidth,
				}}
				className="input-autocomplete-popover"
			>
				<Popover.Body className="p-2">
					<SimpleTable
						data={tableData}
						columns={COLUMNS}
						height={300}
						width={1000}
						scrollable={true}
						setDataChecked={selectedRows => onRowSelect(selectedRows[0])}
						dataChecked={selectedRowRef.current ? [selectedRowRef.current] : []}
						uniquePrefix="code"
						handleRenderCell={onRenderCell}
						onRowClick={({ rowData }: RowMouseEventHandlerParams) => {
							onRowSelect(rowData);
						}}
					/>
				</Popover.Body>
			</Popover>
		);
	};

	return (
		<div className={containerClassName} ref={containerRef}>
			<div className="d-flex align-items-center">
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0',
						labelClassName
					)}
				>
					{label}
				</Form.Label>
				<Form.Control
					className={clsx('spaces px-4 customs-input', inputClassName)}
					ref={inputRef}
					value={query}
					onChange={onSearch}
					onFocus={() => {
						setIsShow(true);
					}}
					disabled={!maKhoThuoc}
					{...props}
				/>
			</div>

			<Overlay
				placement="bottom-start"
				target={inputRef.current}
				container={containerRef.current}
				offset={[0, 3]}
				show={isShow}
			>
				{renderTable()}
			</Overlay>
		</div>
	);
};

export default SelectTableButton;
