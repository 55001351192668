import * as Yup from "yup";
import { Formik } from "formik";
import { FC, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../../../appContext/AppContext";
import { IconBack } from "../../../../../component/IconSvg";
import { CODE, FORM_CODE } from "../../../../../utils/Constant";
import { formatDateDTO, handleAgeCalculate } from "../../../../../utils/FormatUtils";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../../../components/tab-quan-ly-hop-dong/QuanLyHopDong";
import { addNhanVien, updateNhanVien } from "../../../../services/KhamDoanHopDongServices";
import { initialValuesThemHD } from "./constants/ThemNhanVien";
import FormThemMoiNhanVien from "./FormThemMoiNhanVien";
import { AddNhanVien } from "./models/ThemNhanVienModel";
import { useIntl } from "react-intl";

type Props = {
    show: boolean;
    onHide: () => void;
};

const ModelThemMoiNhanVien: FC<Props> = (props) => {
    const { show, onHide } = props;
    const intl = useIntl();
    const { dataEditNV, TTHopDong } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const { setIsLoading } = useContext(AppContext);
    const [dataTienSuBanThan, setDataTienSuBanThan] = useState<any>([]);

    const validationSchema = Yup.object({
        code: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        name: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        phone: Yup.number().typeError("Vui lòng nhập số").required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        dob: Yup.date().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        idNumber: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        examExpectedDate: Yup.date().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        genderObj: Yup.object().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        examPackage: Yup.object().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
      });

    const handleFormSubmit = async (values: AddNhanVien) => {
        const patientValues = values;
        const now = new Date();
        if (new Date(patientValues.dob) > now) {
          return toast.warning("Ngày sinh không được lớn hơn ngày hiện tại!");
        }

        const obs = {
            familyMedicalHistory: values.medicalHistoryFamily,
            medicalHistory: values.medicalHistory,
        };
        const newDataTienSuBanThan = dataTienSuBanThan?.filter((item: any) => 
            item.diseaseName ||
            item.discoveryYear || 
            item.occDiseaseName || 
            item.occDiscoveryYear
          );
        patientValues.obs = obs;
        patientValues.termModelCode = FORM_CODE.SO_KHAM_SUC_KHOE;
        patientValues.personalMedicals = newDataTienSuBanThan;
        patientValues.patientCode = patientValues.patientCode ? patientValues.patientCode : null;
        patientValues.maritalStatusId = patientValues?.maritalStatus?.id || "";
        patientValues.contractId = TTHopDong?.id || "";
        patientValues.maritalStatusName = patientValues?.maritalStatus?.name || "";

        patientValues.divisionId = patientValues?.division?.id || "";
        patientValues.divisionName = patientValues?.division?.name || "";

        patientValues.gender = patientValues?.genderObj?.name || "";

        patientValues.examPackageId = patientValues?.examPackage?.id || "";
        patientValues.examPackageName = patientValues?.examPackage?.name || "";

        patientValues.groupId = patientValues?.group?.id || "";
        patientValues.groupName = patientValues?.group?.name || ""; 
        patientValues.dob = formatDateDTO(patientValues?.dob || "");  
        patientValues.issuingDate = formatDateDTO(patientValues?.issuingDate || ""); 
        patientValues.workStartDate = formatDateDTO(patientValues?.workStartDate || ""); 
        patientValues.examExpectedDate = formatDateDTO(patientValues?.examExpectedDate || ""); 

        try {
            setIsLoading(true);
            let { data } = (patientValues?.id || dataEditNV?.id)
                ? await updateNhanVien({
                    ...patientValues,
                    id: patientValues?.id || dataEditNV?.id
                })
                : await addNhanVien(patientValues);
            if (CODE.SUCCESS === data?.code) {
                toast.success(((patientValues?.id || dataEditNV?.id) ? "Cập nhật" : "Thêm") + " bệnh nhân thành công");
                onHide()
            } else {
                let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
                toast.warning(errorMesage);
            }
        } catch (error) {
            toast.warning("Xảy ra lỗi, vui lòng thử lại!");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Formik<AddNhanVien>
            initialValues={{ ...initialValuesThemHD }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
            }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <div className="receive overflow-auto" id="form-them-moi-hop-dong" >
                        <Modal
                            centered
                            show={show}
                            onHide={onHide}
                            size="sm"
                            fullscreen
                            className="page-full spaces h-calc-vh-50"
                        >
                            <Modal.Header className="py-3 header-modal">
                                <div className="d-flex gap-6">
                                    <div
                                        className="d-flex border-right align-items-center gap-3 cursor-pointer"
                                        onClick={onHide}
                                    >
                                        <IconBack />
                                        <span className="fw-500 ">Quay lại &nbsp;</span>
                                    </div>
                                    <Modal.Title >
                                        <div className="text-transform-none fs-3">{`${!dataEditNV?.id ? 'Thêm mới' : 'Cập nhật'} nhân viên`}</div>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>

                            <Modal.Body className="border-right-0  border-left-0 border-top-0 py-5 spaces px-5 bg-white pe-2e">
                                <FormThemMoiNhanVien thongTinHD={TTHopDong} dataTienSuBanThan={dataTienSuBanThan} setDataTienSuBanThan={setDataTienSuBanThan}/>
                            </Modal.Body >
                            <Modal.Footer>
                                <Button type="submit" className=" min-w-80px btn-fill btn mr-5" onClick={() => handleSubmit()}>
                                    Lưu
                                </Button>
                            </Modal.Footer>
                        </Modal >
                    </div>
                </Form>
            )}
        </Formik >
    );
};
export default ModelThemMoiNhanVien;

