import React, { useContext, useEffect, useState } from "react";
import "./QuanLyHopDong.scss";
import { Col, Row } from "react-bootstrap";
import { DanhSachDichVuBenhNhanInterface } from "../../../models/KhamDoanModel"
import { TableCustomCell } from "../../../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../../../component/table/components/TableCustomHeader";
import { Column } from "react-table";
import { TableCustom } from "../../../../component/table/table-custom/TableCustom";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../tab-quan-ly-hop-dong/QuanLyHopDong"
import { formatDateToDDMMYYYY, formatDateToDDMMYYYYHHMM, handleAgeCalculate } from "../../../../utils/FormatUtils"
import { CODE, RESPONSE_MESSAGE } from "../../../../utils/Constant";
import { getListDichVuCuaNhanVien } from "../../../services/KhamDoanHopDongServices"
import { toast } from "react-toastify";
import { convertNumberPrice } from "../../../../utils/FormatUtils";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { handleNameStatus } from "../../../../utils/AppFunction";
import moment from "moment";

type Props = {};

const ThongTinNhanVien = ({ }: Props) => {
  const { TTNhanVien } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext);
  const { workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext);
  const [dsDichVuNhanVien, setDsDichVuNhanVien] = useState([]);
  const DanhSachDichVuBenhNhanColumns: ReadonlyArray<
    Column<DanhSachDichVuBenhNhanInterface>
  > = [
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"STT"}
            className=" text-center text-white align-middle bg-pri"
          />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={
              props.row.index + 1}
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"Tên dịch vụ"}
            className="text-center text-white align-middle bg-pri min-w-200px"
          />
        ),
        id: "name",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="s-2"
            data={props.data[props.row.index].name}
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"Phòng thực hiện"}
            className="text-center text-white align-middle bg-pri min-w-200px"
          />
        ),
        id: "deptLaboratoryName",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="s-2"
            data={props.data[props.row.index]?.attributes?.deptLaboratoryName}
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"Số lượng"}
            className=" text-center text-white align-middle bg-pri min-w-100px"
          />
        ),
        id: "soLuong",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={"1"}
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"Đơn giá"}
            className=" text-center text-white align-middle bg-pri min-w-100px"
          />
        ),
        id: "svcFee",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={convertNumberPrice(props.data[props.row.index]?.attributes?.svcFee || "")}
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"Trạng thái"}
            className=" text-center text-white align-middle bg-pri min-w-100px"
          />
        ),
        id: "thai",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={props.data[props.row.index]?.age}
            className="text-left"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachDichVuBenhNhanInterface>
            tableProps={props}
            title={"Ngoài gói"}
            className=" text-center text-white align-middle bg-pri min-w-100px"
          />
        ),
        id: "goi",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={props.data[props.row.index]?.age}
            className="text-left"
          />
        ),
      },
    ];
  useEffect(() => {
    getDanhSachGoiKham();
  }, [TTNhanVien?.id])
  
  const getDanhSachGoiKham = async (filter?: any) => {
    if (TTNhanVien?.id) {
      try {
        const { data } = await getListDichVuCuaNhanVien(TTNhanVien?.id);
        if (CODE.SUCCESS === data?.code) {
          setDsDichVuNhanVien(data?.data || []);
        } else {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        }
      } catch {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      } finally {
      }
    } else {
      setDsDichVuNhanVien([]);
    }
  };

  return (
    <div className="bg-white position-relative spaces gap-6 spaces w-100 mb-6 fl-1">
      <div className="spaces w-100 justify-content-center mt-5">
        <Row className="border-bottom mx-0">
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Mã NV:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.code || ""}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-110">Tên nhân viên:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.name || ""}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Ngày sinh:</span>
              <span className="fw-light text-truncate">
                {formatDateToDDMMYYYY(TTNhanVien?.dob)}
              </span>
            </div>
          </Col>
          <Col xs="1">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-40">Tuổi:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.age || TTNhanVien?.dob && handleAgeCalculate(TTNhanVien?.dob) || ""}
              </span>
            </div>
          </Col>
          <Col xs="2">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-60">Giới tính:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.gender || ""}
              </span>
            </div>
          </Col>


          <Col xs="6">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Địa chỉ:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.address || ""}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Đối tượng:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.position || "Khám đoàn"}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-80">Gói khám:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.packageName || ""}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Đón tiếp lúc:</span>
              <span className="fw-light text-truncate">
                {formatDateToDDMMYYYYHHMM(TTNhanVien?.dateStarted)}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-110">Trạng thái khám:</span>
              <span className="fw-light text-truncate">
                {handleNameStatus(workFlowStatus.currStatusCode)}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-140">Tình trạng hôn nhân:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.maritalStatusName || ""}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-110">Người giới thiệu:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.referringPerson || ""}
              </span>
            </div>
          </Col>
        </Row>

      </div>
      <div className="border-left-0  bg-white ">
        <div className=" bg-white" >
          <h4 className="text-title border-bottom fw-bold fs-4 spaces mb-0  px-10">
            Danh sách dịch vụ
          </h4>
        </div>
        <TableCustom<any>
          className={"h-bang-ds-dv-bn"}
          data={dsDichVuNhanVien || []}
          columns={DanhSachDichVuBenhNhanColumns}
          verticalScroll={true}
        />
      </div>
    </div>
  );
};

export default ThongTinNhanVien;
