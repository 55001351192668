import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import NumberField from '../../../../../component/number-field';
import TextField from '../../../../../component/TextField';
import { LOAI_VAT_TU } from '../../../../constants/VatTu';
import { IVatTuItem } from '../../../../models/VatTuModels';
import { searchVatTu } from '../../../../services/VatTuService';
import { useKeVatTuContext } from '../../context';

type Props = {
	data: any;
	onSave: (value: any) => void;
};

const TableSelectVatTuRowEdit = ({ data, onSave }: Props) => {
	const { values, setValues } = useFormikContext<any>();
	const { treeValue } = useKeVatTuContext();

	const danhSachVatTuRef = useRef<IVatTuItem[]>([]);

	useEffect(() => {
		if (treeValue?.id) {
			getDanhSachVatTu();
		}
	}, [treeValue?.id]);

	useEffect(() => {
		getDetailVatTu(data);
	}, [data?.id]);

	const getDanhSachVatTu = async () => {
		const response: IVatTuItem[] = await searchVatTu({
			warehouseId: treeValue.id,
			materialGroup: LOAI_VAT_TU.VAT_TU,
			pageIndex: 1,
			pageSize: 100_000,
		});
		danhSachVatTuRef.current = response;
	};

	const getDetailVatTu = async (value: any) => {
		if (!value?.id) return;

		if (!danhSachVatTuRef.current.length) {
			await getDanhSachVatTu();
		}

		const vatTuDetail = danhSachVatTuRef.current.find(
			row => row.id === Number(value.idVatTu || value.id)
		);

		setValues({
			...values,
			tenVatTu: value?.tenVatTu,
			donVi: value?.donVi,
			tonKho: vatTuDetail?.iventory,
			soLuong: value?.soLuong,
		});
	};

	const onSaveRow = () => {
		if (!values?.soLuong) {
			toast.warn('Chưa nhập số lượng vật tư cần kê');
			return;
		}

		if (values.soLuong > values.tonKho) {
			toast.warn('Số lượng vật tư kê đơn không được lớn hơn số lượng vật tư khả dụng');
			return;
		}

		onSave({
			...data,
			tenVatTu: values.tenVatTu,
			donVi: values.donVi,
			tonKho: values.tonKho,
			soLuong: values.soLuong,
		});

		setValues({
			...values,
			tenVatTu: '',
			donVi: '',
			tonKho: 0,
			soLuong: 0,
		});
	};

	return (
		<Row>
			<Col xs={4}>
				<TextField
					label="Tên vật tư:"
					className="spaces w-100"
					inputClassName="w-100"
					name="tenVatTu"
					labelClassName="ms-0 min-w-80px"
					disabled
				/>
			</Col>
			<Col xs={2}>
				<TextField
					label="Đơn vị:"
					className="spaces w-100"
					inputClassName="w-100"
					name="donVi"
					labelClassName="ms-0 min-w-60px"
					disabled
				/>
			</Col>
			<Col xs={2}>
				<NumberField
					label="Tồn kho:"
					className="spaces w-100"
					inputClassName="w-100"
					name="tonKho"
					labelClassName="ms-0 min-w-70px"
					disabled
				/>
			</Col>
			<Col xs={2}>
				<NumberField
					label="Số lượng:"
					className="spaces w-100"
					inputClassName="w-100"
					name="soLuong"
					labelClassName="ms-0 min-w-70px"
					disabled={!values?.tenVatTu}
				/>
			</Col>
			<Col xs={2}>
				<Button className="btn-fill" onClick={onSaveRow} disabled={!values?.tenVatTu}>
					<i className="bi bi-check-lg"></i>Xác nhận
				</Button>
			</Col>
		</Row>
	);
};

export default TableSelectVatTuRowEdit;
