import React, { useContext, useEffect } from "react";
import LabelRequired from "../../../../component/LabelRequired";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../component/TextField";
import { KhamLamSangContext } from "../KhamLamSang";
import AutocompleteObjectV2 from "../../../../component/AutocompleteObjectV2";

type Props = { };
const listClassification = [
  { code: "1", name: "Loại 1" },
  { code: "2", name: "Loại 2" },
  { code: "3", name: "Loại 3" }
]

const BMIComponent = (props: Props) => {
  const { isView, bmiData, setBMIData } = useContext(KhamLamSangContext);

	useEffect(() => {
    handleBMI();
    bmiData?.classification && setBMIData({
			...bmiData,
			classification: listClassification.find((item) => item.code === bmiData.classification)
		});
  }, [bmiData?.height, bmiData?.weight]);

	const handleBMI = () => {
    let chieuCao = Number(bmiData?.height) / 100;
    let canNang = Number(bmiData?.weight);
    let bmi: string | number = "";

    if (chieuCao && canNang) {
      bmi = (canNang / (chieuCao * chieuCao)).toFixed(1);
    }
    setBMIData({
			...bmiData,
			bmi: bmi
		});
  };

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
		setBMIData({
			...bmiData,
			[name]: value
		});
  };

  const handleChangeCombobox = (name: string, value: any) => {
    setBMIData({
      ...bmiData,
      [name]: value
    });
  };

  return (
    <>
      <Row className="spaces pt-8">
        <Col xs={3} className="d-flex spaces pr-4">
          <LabelRequired label="Cân nặng" className="min-w-80px spaces ml-5" />
          <TextField
            name="weight"
            className="min-w-130px"
            disabled={isView}
            value={bmiData?.weight || ""}
            onChange={(e: any) => handleChange(e)}
          />
        </Col>
        <Col xs={1}>
          <div className="text-lable-input">kg</div>
        </Col>
        <Col xs={3} className="d-flex spaces pr-4">
          <LabelRequired label="Chiều cao" className="min-w-80px spaces ml-5" />
          <TextField
            name="height"
            disabled={isView}
            value={bmiData?.height || ""}
            onChange={(e: any) => handleChange(e)}
          />
        </Col>
        <Col xs={1}>
          <div className="text-lable-input">cm</div>
        </Col>
        <Col xs={3} className="d-flex spaces pr-4">
          <LabelRequired label="BMI" className="min-w-80px spaces ml-5" />
          <TextField
            name="bmi"
            disabled={isView}
            value={bmiData?.bmi || ""}
            onChange={(e: any) => handleChange(e)}
          />
        </Col>
        <Col xs={1}>
          <div className="text-lable-input">kg/cm2</div>
        </Col>
        <Row className="spaces pt-8">
          <Col xs={12} className="d-flex spaces pr-4">
            <LabelRequired
              label="Phân loại"
              className="min-w-80px spaces ml-5"
            />
            <AutocompleteObjectV2
              options={listClassification || []}
              value={bmiData?.classification || null}
              name="classification"
              onChange={(selectedOption) =>
                handleChangeCombobox("classification", selectedOption)
              }
              isClearable={false}
              className="autocomplete-custom-tiep-nhan radius spaces h-29"
              valueField="code"
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default BMIComponent;
