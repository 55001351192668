import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import KhamLamSang from "../tab-kham-lam-sang/KhamLamSang";
import QuanLyHopDong from "../tab-quan-ly-hop-dong/QuanLyHopDong";
import ThanhToanHD from "../tab-thanh-toan-hop-dong/ThanhToanHD";
import { hasAuthority } from "../../../utils/Permission";
import TabMenu from "../../../component/TabMenu";

type ThongTinHopDongProps = {
  setActiveTab?: any,
}

const listTab = [
  {
    permission: "CIS.MODULE.KHAM_DOAN.HOP_DONG",
    eventKey: "1",
    title: 'Quản lý hợp đồng',
    component: <QuanLyHopDong />
  },
  {
    eventKey: "2",
    permission: "CIS.MODULE.KHAM_DOAN.KLS",
    title: 'Khám lâm sàng',
    component: <KhamLamSang />
  },
  {
    eventKey: "3",
    permission: "CIS.MODULE.KHAM_DOAN.THANH_TOAN",
    title: 'Thanh toán',
    component: <ThanhToanHD />
  },
];

const ThongTinHopDong: FC<ThongTinHopDongProps> = React.memo(
  ({
    setActiveTab,
  }) => {
    const [listTabKD, setListTabKD] = useState<any>([]);

    useEffect(() => {
      handleCheckTabsByPermissions();
    }, [])
    

    const handleCheckTabsByPermissions = () => {
      const checkedTab: any[] = [];
      listTab.forEach((tab) => {
        if (hasAuthority(tab.permission)) {
          const checkedMenuItems = {
            ...tab
          };
          checkedTab.push(checkedMenuItems);
        }
      });
      setListTabKD(checkedTab);
    }

    return (
      <div className="reception-list__container spaces py-0 bg-white">
        <Row className="h-100">
          <Col xs={12} className="spaces px-0">
            <TabMenu danhsachTabs={listTabKD} />
          </Col>
        </Row>
      </div>
    );
  }
);
export default ThongTinHopDong;
