import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ConfirmDialog } from '../../../../component/ConfirmDialog';
import { IconDoubleCheck } from '../../../../component/IconSvg';
import { CODE, RESPONSE_MESSAGE } from '../../../../utils/Constant';
import { WfConstTracker } from '../../../../workflows/Constant';
import { updateWfWorkflows } from '../../../../workflows/services/WfWorkflowsService';
import DichVuChuaCoKetQuaDialog from '../../../components/modal-ke-thuoc/DichVuChuaCoKetQuaDialog';
import { MEDICAL_CONCLUSION_MODALITY, MEDICAL_CONCLUSION_OPTIONS } from '../../../constants/phan-he-kham-benh/ConstantPhanHeKhamBenh';
import { WORKFLOW_STATUS } from '../../../constants/Thuoc';
import { PhanHeTiepDonContext } from '../../../contexts/PhanHeTiepDonContext';
import { getDataXuTriService, updateXuTri } from '../../../services/KhamBenhService';
import { getDichVuChuaCoKetQua } from '../../../services/ThuocService';

type Props = {
	onSaveForm: (callbackFn?: () => Promise<void>) => Promise<void>;
	patientInfo: {
		orderId: string;
		patientId: string;
		visitId: string;
		encounterId: string;
	};
	drugInfo: {
		doctorComment: string;
	};
	setIsLoading: (value: boolean) => void;
	onClose?: () => void;
};

const ButtonCapToaChoVe = ({ patientInfo, drugInfo, onSaveForm, setIsLoading, onClose }: Props) => {
	const { benhNhanInfo, setBenhNhanInfo } = useContext(PhanHeTiepDonContext);

	const [openDichVuDialog, setOpenDichVuDialog] = useState<boolean>(false);
	const [orderData, setOrderData] = useState<any[]>([]);
	const [xuTriData, setXuTriData] = useState<any>();
	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

	const XU_TRI_CAP_TOA_CHO_VE_OPTIONS = MEDICAL_CONCLUSION_MODALITY.CAP_TOA;

	const onButtonClick = async () => {
		!xuTriData && getXuTriData();
		getListDichVuChuaCoKetQua();
	};

	const getListDichVuChuaCoKetQua = async () => {
		try {
			const { code, data } = await getDichVuChuaCoKetQua({
				patientId: patientInfo.patientId,
				visitId: patientInfo.visitId,
			});
			if (CODE.SUCCESS === code) {
				if (data.length) {
					setOrderData(data);
					setOpenDichVuDialog(true);
				} else {
					setOpenConfirmDialog(true);
				}
			}
		} catch (error) {
			toast.error(RESPONSE_MESSAGE.ERROR);
			console.error(error);
		}
	};

	const getXuTriData = async () => {
		try {
			const {
				data: { code, data },
			} = await getDataXuTriService(patientInfo.orderId);
			if (CODE.SUCCESS === code) {
				setXuTriData(data.encounterDiagnosis);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateThongTinXuTri = async () => {
		try {
			setIsLoading(true);
			await updateWfWorkflows(WfConstTracker.CLINICAL, {
				objectId: patientInfo.orderId,
				status: WORKFLOW_STATUS.CLINICAL_CONCLUSION,
			});

			const { data } = await updateXuTri({
				patientId: patientInfo.patientId,
				visitId: patientInfo.visitId,
				encounterId: patientInfo.encounterId,
				encounterDiagnosis: {
					...xuTriData,
					medicalConclusionDate: new Date(),
					medicalConclusionModality: XU_TRI_CAP_TOA_CHO_VE_OPTIONS.code,
					medicalConclusionModalityCode: XU_TRI_CAP_TOA_CHO_VE_OPTIONS.code,
					medicalConclusionModalityName: XU_TRI_CAP_TOA_CHO_VE_OPTIONS.name,
					medicalConclusionCode: MEDICAL_CONCLUSION_OPTIONS.CHUA_XAC_DINH.code,
                    medicalConclusionName: MEDICAL_CONCLUSION_OPTIONS.CHUA_XAC_DINH.name,
					doctorComment: drugInfo.doctorComment,
				},
				modalityDetails: {},
				isClinicalTransfer: false,
			});

			if (CODE.SUCCESS === data.code) {
				setBenhNhanInfo({
					...benhNhanInfo,
					shouldUpdate: !benhNhanInfo?.shouldUpdate,
				});
				onClose?.();
			}
		} catch (error) {
			toast.error(RESPONSE_MESSAGE.ERROR);
			console.error(error);
		} finally {
			setIsLoading(false);
			setOpenConfirmDialog(false);
		}
	};

	const onCapToaChoVe = () => {
		onSaveForm(updateThongTinXuTri);
	};

	return (
		<>
			<Button className="btn-fill" onClick={onButtonClick}>
				<IconDoubleCheck /> Cấp toa cho về
			</Button>

			{openDichVuDialog && (
				<DichVuChuaCoKetQuaDialog
					open={openDichVuDialog}
					onClose={() => {
						setOpenDichVuDialog(false);
					}}
					data={orderData}
				/>
			)}

			{openConfirmDialog && (
				<ConfirmDialog
					className="z-index-1060"
					show={openConfirmDialog}
					title="Thông báo"
					message="Bạn có chắc chắn muốn cấp toa cho về bệnh nhân này không ?"
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setOpenConfirmDialog(false);
					}}
					onYesClick={onCapToaChoVe}
				/>
			)}
		</>
	);
};

export default ButtonCapToaChoVe;
