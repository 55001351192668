import { Column } from "react-table";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { LIST_COLOR_TRANG_THAI_PHIEU } from "../../../utils/Constant";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";

const chiDinhXetNghiemColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => <div className="p-0"></div>,
    id: "dv",
    Cell: ({ ...props }) => (
      <TableCustomCell
        tableProps={props}
        listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
        className="d-flex justify-content-between"
        data={
          <div className="ms-5">
            <p className="my-1">{props.data[props.row.index]?.date}</p>
            <p className="my-1">{props.data[props.row.index]?.title}</p>
          </div>
        }
      />
    ),
  },
];

const columnsDSXetNghiem = [
  {
    title: "Tên xét nghiệm",
    field: "name",
    className: "spaces width-40",
    render: (rowData: any) => {
      return (
        <div
          className={`${
            (rowData?.obs?.value > rowData?.numeric?.hiNormal
              ? "hiNormal"
              : "") +
            (rowData?.obs?.value < rowData?.numeric?.lowNormal
              ? "lowNormal"
              : "")
          }`}
        >
          {rowData?.name}
        </div>
      );
    },
  },
  {
    title: "SL",
    field: "quantity",
    className: "spaces width-5 text-center",
    render: (rowData: any) => {
      const DEFAULT_QUANTITY = 1;
      return (
        <div
          className={`${
            (rowData?.obs?.value > rowData?.numeric?.hiNormal
              ? "hiNormal"
              : "") +
            (rowData?.obs?.value < rowData?.numeric?.lowNormal
              ? "lowNormal"
              : "")
          }`}
        >
          {DEFAULT_QUANTITY}
        </div>
      );
    },
  },
  {
    title: "Kết quả",
    field: "ketQua",
    className: "spaces width-10 text-center",
    render: (rowData: any) => {
      return (
        <div
          className={`${
            (rowData?.obs?.value > rowData?.numeric?.hiNormal
              ? "hiNormal"
              : "") +
            (rowData?.obs?.value < rowData?.numeric?.lowNormal
              ? "lowNormal"
              : "")
          }`}
        >
          {rowData?.obs?.value || ""}
        </div>
      );
    },
  },
  {
    title: "",
    field: "HL",
    className: "spaces width-5 text-center",
    render: (rowData: any) => {
      return (
        <div
          className={`${
            (rowData?.obs?.value > rowData?.numeric?.hiNormal
              ? "hiNormal"
              : "") +
            (rowData?.obs?.value < rowData?.numeric?.lowNormal
              ? "lowNormal"
              : "")
          }`}
        >
          {rowData?.obs?.value > rowData?.numeric?.hiNormal ? "H" : ""}
          {rowData?.obs?.value < rowData?.numeric?.lowNormal ? "L" : ""}
        </div>
      );
    },
  },
  {
    title: "Đơn vị",
    field: "donVi",
    className: "spaces width-6 text-center",
    render: (rowData: any) => {
      return (
        <div
          className={`${
            (rowData?.obs?.value > rowData?.numeric?.hiNormal
              ? "hiNormal"
              : "") +
            (rowData?.obs?.value < rowData?.numeric?.lowNormal
              ? "lowNormal"
              : "")
          }`}
        >
          {rowData?.numeric?.units}
        </div>
      );
    },
  },
  {
    title: "GTBT",
    field: "gtbt",
    className: "spaces width-10 text-center",
    render: (rowData: any) => {
      return (
        <div
          className={`${
            (rowData?.obs?.value > rowData?.numeric?.hiNormal
              ? "hiNormal"
              : "") +
            (rowData?.obs?.value < rowData?.numeric?.lowNormal
              ? "lowNormal"
              : "")
          }`}
        >
          {rowData?.numeric
            ? rowData?.numeric?.lowNormal + " - " + rowData?.numeric?.hiNormal
            : ""}
        </div>
      );
    },
  },
  { title: "Loại bệnh phẩm", field: "type", className: "spaces width-15" },
  {
    title: "Đối tượng", field: "doiTuong", className: "spaces width-15", render: (rowData: any) => rowData?.objectTypeName || ""},
];

const xetNghiemColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"STT"}
        className=" text-center text-white"
      />
    ),
    id: "STT",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center"
        data={(props.row.index + 1).toString()}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Tên xét nghiệm"
        className=" min-w-250px text-center "
      />
    ),
    id: "Tên xét nghiệm",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-150px "
        data={props.data[props.row.index]?.serviceName || ""}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="SL"
        className=" text-center "
      />
    ),
    id: "Số lượng",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-50px text-center "
        data={props.data[props.row.index]?.soLuong}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Kết quả"
        className="min-w-200px  text-center"
      />
    ),
    id: "Kết quả",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-200px text-start "
        data={props.data[props.row.index]?.loaiCongKham}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Loại bệnh phẩm"
        className="min-w-200px  text-center"
      />
    ),
    id: "Loại bệnh phẩm",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-200px text-start "
        data={props.data[props.row.index]?.loaiCongKham}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Đối tượng"
        className="min-w-200px text-center"
      />
    ),
    id: "Đối tượng",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-200px text-start "
        data={props.data[props.row.index]?.ghiChu}
      />
    ),
  },
];

export { chiDinhXetNghiemColumn, columnsDSXetNghiem, xetNghiemColumn };
