import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React, { useEffect } from 'react';

const TINYMCE_API_KEY = '8j045twdc10hmod9xgmqnsemuhuapt7fpnn9xe9stj623g69';

const NhapKetQuaCDHAEditor = (props: IAllProps) => {
	return (
		<Editor
			onDrop={(e: DragEvent) => {
				const targetElement = e.target as HTMLElement;
				const file = e.dataTransfer?.files[0];

				if (!file) return;

				const isValidTarget =
					(file &&
						targetElement.tagName === 'P' &&
						targetElement.parentNode?.nodeName === 'td') ||
					targetElement.tagName === 'TD';

				if (isValidTarget) {
					e.preventDefault();

					while (targetElement.firstChild) {
						targetElement.firstChild.remove();
					}

					const reader = new FileReader();
					reader.onload = () => {
						const base64 = reader.result as string;
						const img = document.createElement('img');
						img.style.width = `100%`;
						img.style.height = `100%`;
						img.style.objectFit = 'cover';
						img.style.display = 'block';
						img.src = base64;
						targetElement.appendChild(img);
					};
					reader.readAsDataURL(file);
				}
			}}
			apiKey={TINYMCE_API_KEY}
			init={{
				branding: false,
				height: '500',
				min_height: 500,
				menubar: false,
				plugins:
					'preview importcss searchreplace directionality fullscreen ' +
					'image table charmap nonbreaking anchor insertdatetime advlist lists' +
					'wordcount help charmap quickbars code image link',
				toolbar:
					'undo redo blocks fontfamily fontsize bold italic underline strikethrough' +
					'| align numlist bullist table | lineheight outdent indent| forecolor' +
					'backcolor removeformat | fullscreen preview print code image link',
				content_style:
					'body { font-family:Time New Roman, Helvetica,Arial,sans-serif; font-size:14px }',
				toolbar_mode: 'sliding',
				contextmenu: 'image table',
				block_unsupported_drop: false,
			}}
			{...props}
		/>
	);
};

export default NhapKetQuaCDHAEditor;
