import axios from "axios";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/Constant";
import { paramsConfig, urlParamsConfig } from "../../utils/ParamsUtils";
import { SearchObject } from "../models/PhanHeTiepNhanModel";


const API_PATH = process.env.REACT_APP_EMR_API_URL;
const API_PATH_ADMINISTRATION = process.env.REACT_APP_ADMIN_API_URL;
const API_PATH_BENH_NHAN = API_PATH + "/patients";
const API_PATH_TIEP_DON = API_PATH + "/admission";
const API_PATH_CLINICAL = process.env.REACT_APP_CIS_API_URL;
const API_PATH_XADMIN = process.env.REACT_APP_XADMIN_API_URL;


export const searchByPage = (searchObject: SearchObject) => {
    let url = API_PATH_TIEP_DON + "/search";
    return axios.get(urlParamsConfig(url, searchObject));
};

export const getData = async (searchObject: any) => {
    let url = API_PATH_BENH_NHAN + '/search';
    return axios.get(urlParamsConfig(url, searchObject));
}

export const getPatientId = async (id: string) => {
    let url = API_PATH_BENH_NHAN + `/${id}`;
    return axios.get(url);
}

export const addBenhNhan = (data: any) => {
    return axios.post(API_PATH_TIEP_DON, data);
};

export const updateBenhNhan = (data: any) => {
    let url = API_PATH_TIEP_DON;
    return axios.put(url, data);
};

export const getBenhNhan = (searchObject: {
    code?: string;
    idNumber?: string;
}) => {
    let url = API_PATH_BENH_NHAN;
    return axios.get(urlParamsConfig(url, searchObject));
}

export const getByIdBenhNhan = (id: string) => {
    let url = API_PATH_TIEP_DON + `/${id}`;
    return axios.get(url);
}

export const getListDangKyKham = () => {
    let url = API_PATH + "/visit-types/search";
    return axios.get(url, { params: { pageSize: MAX_PAGE_SIZE } });
}

export const getListQuocTich = () => {
    let url = API_PATH_XADMIN + "/country/search";
    return axios.get(url, { params: { pageSize: MAX_PAGE_SIZE } });
}

export const getListDanToc = () => {
    let url = API_PATH_XADMIN + "/cEthnics/search";
    return axios.get(url, {
        params: {
            pageIndex: DEFAULT_PAGE_INDEX,
            pageSize: MAX_PAGE_SIZE,
        }
    });
}

export const getListPhongKham = () => {
    let url = API_PATH_XADMIN + "/departments/search";
    let TYPE = 1; //type=1 là khám bệnh,
    let LEVEL = 2; //level 1 là khoa, level 2 là phòng
    return axios.get(url, {
        params: {
            pageIndex: DEFAULT_PAGE_INDEX,
            pageSize: MAX_PAGE_SIZE,
            type:TYPE,
            level:LEVEL
        }
    });
}


//Nationalities
export const getAllNation = (searchObject: any) => {
    let url = API_PATH_ADMINISTRATION + "/nationalities";
    return axios.get(urlParamsConfig(url, searchObject));
}

export const getAllProvince = (searchObject: any) => {
    let url = API_PATH_ADMINISTRATION + "/provinces";
    return axios.get(urlParamsConfig(url, searchObject));
}

export const getDistrictByProvinceId = (searchObject: any) => {
    let url = API_PATH_ADMINISTRATION + '/districts'
    return axios.get(urlParamsConfig(url, searchObject));
}

export const getCommuneByDistrictId = (searchObject: any) => {
    let url = API_PATH_ADMINISTRATION + '/communes'
    return axios.get(urlParamsConfig(url, searchObject));
}

export const getAllCategory = (searchObject: any) => {
    let url = API_PATH_ADMINISTRATION + "/simple-category";
    return axios.get(urlParamsConfig(url, searchObject));
}


//Phong kham
export const getDSPhongKham = () => {
    let url = API_PATH + '/admission/department-info'
    return axios.get(url);
}

// Yêu cầu khám
export const getDanhSachYeuCauKham = () => {
    let url = API_PATH_CLINICAL + '/concepts/service/58'
    return axios.get(url)
}

//upload image
export const uploadImage = (patientId: string, formData: any) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    let url = API_PATH_BENH_NHAN + `/benh-nhan/avatar?patientId=${patientId}`;
    return axios.post(url, formData, config)
}

// lich sử khám
export const getListSu = (patientId: string) => {
    let url = API_PATH + `/admission/medical-records/patient/${patientId}`
    return axios.get(url);
}

//Thông tin khám bệnh
export const getChiTietBenhNhan = (caseId: string, searchObject: any) => {
    let url = API_PATH_BENH_NHAN + `/benh-nhan/detail/${caseId}`
    return axios.get(url,paramsConfig(searchObject))
}

//DS tiếp nhận
export const getDSTiepNhan = (searchObject: any) => {
    let url = API_PATH_BENH_NHAN + '/benh-nhan/dang-kham';
    return axios.post(url,{}, paramsConfig(searchObject));
}


//FAKE API
const API_PATH_FAKE = "http://localhost:3000/"
export const addBenhNhanFake = (data: any) => {
    return axios.post(API_PATH_FAKE + 'tiep-nhan', data);
};
export const updateBenhNhanFake = (id: any,data: any) => {
    return axios.put(API_PATH_FAKE + 'tiep-nhan/'+ id, data);
};
export const getListBenhNhanFake = () => {
    return axios.get(API_PATH_FAKE + 'tiep-nhan');
};
export const getChiTietBenhNhanFake = (id: number, searchObject: any) => {
    let url = API_PATH_FAKE + `tiep-nhan/${id}`
    return axios.get(url,paramsConfig(searchObject))
}

export const deleteBenhNhanFake = (id: number) => {
    let url = API_PATH_FAKE + `tiep-nhan/${id}`
    return axios.delete(url)
};


export const deleteBenhNhan = async (encounterId: string) => {
    let url = API_PATH_TIEP_DON + `?encounterId=${encounterId}`;
    return axios.delete(url);
}