import Fuse from "fuse.js";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import SearchField from "../../../phan-he-kham-benh/components/tab-kham-benh/modal-chon-ket-qua-dich-vu/components/SearchField";
import TableGroupSelection from "../../../phan-he-kham-benh/components/tab-kham-benh/modal-chon-ket-qua-dich-vu/components/TableGroupSelection";
import { KetQuaDichVu } from "../../../phan-he-kham-benh/models/ThongTinKhamBenhModel";
import { KetQuaDichVuColumns } from "../../../phan-he-kham-benh/components/tab-kham-benh/modal-chon-ket-qua-dich-vu/components/Columns";
import { IconButtonSave } from "../../../component/IconSvg";
import { RESPONSE_MESSAGE } from "../../../utils/Constant";
import {
  IKetQuaCanLamSangResponse,
  Term,
} from "../../../phan-he-kham-benh/models/KetQuaCanLamSangModel";
import { Column } from "react-table";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import IndeterminateCheckbox from "../../../phan-he-kham-benh/components/tab-kham-benh/modal-chon-ket-qua-dich-vu/components/IndeterminateCheckbox";
import { formatDateAdvanceToString } from "../../../utils/FormatUtils";
import { getDsKetQuaDichVu } from "../../services/PhanHeChuyenKhoaService";
import _ from "lodash";

interface Props {
  open: boolean;
  handleClose: () => void;
  onSave: (data: KetQuaDichVu[]) => void;
  visitId: string;
  ketQuaCLS: any;
  selectedRow: any;
}

interface KetQuaDichVuNode extends KetQuaDichVu {
  parentId?: number;
  subRows?: KetQuaDichVuNode[] | null;
}

const ModalChonKetQuaDichVu = ({
  open,
  handleClose,
  onSave,
  visitId,
  ketQuaCLS,
  selectedRow,
}: Props) => {
  const [selectedRows, setSelectRows] = useState<KetQuaDichVuNode[]>([]);
  const [treeData, setTreeData] = useState<any>([]);
  const initialTreeDataRef = useRef<any>([]);
  useEffect(() => {
    updatePageData();
  }, []);
  
  useEffect(() => {
    setSelectRows(ketQuaCLS)
  }, [])

  const updatePageData = async () => {
    try {
      const { data } = await getDsKetQuaDichVu(selectedRow?.encounterId);
      const dataConverted = convertDataToTree(data?.data);
      setTreeData(dataConverted);
      initialTreeDataRef.current = dataConverted;
    } catch (error) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      console.error(error);
    }
  };

  const convertDataToTree = (data: IKetQuaCanLamSangResponse[]) => {
    const result: KetQuaDichVuNode[] = data.map((item) => {
      const addtionData = {
        khoaChiDinh: item.fulfillDeptName,
        ngayYLenh: item.indicationTime,
        ngayTraKetQua: item.resultReportingTime,
        orderId: item?.orderId
      };

      return termConvert([item.term], addtionData)[0];
    });
    return result;
  };

  const termConvert = (terms: any, addtionData: any) => {
    const result: KetQuaDichVuNode[] = terms.map((term: any) => ({
      id: term.id.toString(),
      parentId: term.parentId,
      tenDichVu: term.name,
      ketQua: term.obs?.value || "",
      khoaChiDinh: addtionData.khoaChiDinh,
      ngayYLenh: addtionData.ngayYLenh,
      ngayTraKetQua: addtionData.ngayTraKetQua,
      subRows: term.subs?.length ? termConvert(term.subs, addtionData) : null,
      orderId: addtionData?.orderId,
    }));
    return result;
  };

  const [parrentItemTree, setParrentItemTree] = useState<any>([]);
  const handleGetParrent = () => {
    let parrentItem: any = [];
    treeData?.map((item: any) => {
      if (item?.subRows?.length > 0) {
        parrentItem.push({
          ...item,
          subRows: [],
        });
      }
    });
    setParrentItemTree(parrentItem);
  };

  useEffect(() => {
    handleGetParrent();
  }, [treeData?.length]);

  const removeItemsByParentId = (data: any) => {
    const parentIds = new Set(data.map((item: any) => +item.parentId));
    return data.filter((item: any) => !parentIds.has(+item.id));
  }

  const handleFilterData = () => {
    let parrentSubmit = _.cloneDeep(parrentItemTree);
    let selectedRowsRemovedParrent = removeItemsByParentId(selectedRows);

    parrentSubmit.forEach((itemParrent: any) => {
      selectedRowsRemovedParrent.forEach((itemSelected: any) => {
        if (
          itemSelected.parentId == itemParrent.id &&
          itemSelected.ngayYLenh == itemParrent.ngayYLenh &&
          itemSelected.subRows === null
        ) {
          itemParrent.subRows.push(itemSelected);
          itemSelected.isChildren = true;
        }
      });
    });

    let resultSubmit = parrentSubmit
      .filter((itemParrent: any) => itemParrent?.subRows?.length > 0)
      .concat(
        selectedRowsRemovedParrent?.filter(
          (itemSelected: any) => !itemSelected?.isChildren
        )
      );
      return resultSubmit.map((item: any, index: number) => {
        return {
          ...item,
          index: index + 1,
        }
      })
  }


  const handleSubmit = () => {
    if (!selectedRows.length) {
      toast.warning("Chưa chọn kết quả cận lâm sàng");
      return;
    }
    onSave(handleFilterData());
    handleClose();
  };

  const onSearch = (keySearch: string) => {
    if (!keySearch.trim()) {
      setTreeData(initialTreeDataRef.current);
      return;
    }

    const fuse = new Fuse(treeData, {
      includeMatches: true,
      keys: ["tenDichVu", "subRows.tenDichVu"],
    });

    const result = fuse.search(keySearch).map((record) => record.item);
    setTreeData(result);
  };
  const KetQuaDichVuColumns: Column<KetQuaDichVu>[] = [
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Tên dịch vụ"
          className="p-table text-center min-w-250px"
        />
      ),
      accessor: "tenDichVu",
      Cell: ({ row, cell }) => (
        <div
          style={{
            paddingLeft: row.depth * 25,
          }}
        >
          {row.canExpand ? (
            <div className="d-flex align-items-center">
              <span
                className="spaces pe-8 pr-8"
                {...row.getToggleRowExpandedProps()}
              >
                {row.isExpanded ? (
                  <i className="las la-angle-down"></i>
                ) : (
                  <i className="las la-angle-right"></i>
                )}
              </span>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                className="d-flex me-3"
              />
              <span>{cell.value}</span>
            </div>
          ) : (
            <div className="d-flex align-items-center spaces pl-21">
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                className="d-flex me-3"
              />
              <span>{cell.value}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Kết quả"
          className="p-table text-center min-w-150px"
        />
      ),
      accessor: "ketQua",
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Khoa chỉ định"
          className="p-table text-center min-w-150px"
        />
      ),
      accessor: "khoaChiDinh",
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Ngày y lệnh"
          className="p-table text-center min-w-150px"
        />
      ),
      accessor: "ngayYLenh",
      Cell: ({ value }) => {
        return <>{formatDateAdvanceToString(value || "")}</>;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Ngày trả kết quả"
          className="p-table text-center min-w-150px"
        />
      ),
      accessor: "ngayTraKetQua",
      Cell: ({ value }) => <>{formatDateAdvanceToString(value || "")}</>,
    },
  ];

  return (
    <Modal
      size="xl"
      show={open}
      animation
      centered
      className="dialog-background"
    >
      <div className="timKiemBenhNhan">
        <Modal.Header className="py-3 header-modal">
          <Modal.Title className="text-pri">Kết quả dịch vụ</Modal.Title>
          <Button className="btn-close bg-white" onClick={handleClose}></Button>
        </Modal.Header>

        <Modal.Body className="py-16 px-24 spaces">
          <SearchField onSearch={onSearch} />
          <TableGroupSelection<KetQuaDichVu>
            data={treeData}
            containerClassName="h-400px max-h-500px"
            onSelect={(rows) => setSelectRows(rows)}
            columns={KetQuaDichVuColumns}
          />
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center py-1">
          <Button className="btn-fill" type="submit" onClick={handleSubmit}>
            <IconButtonSave />
            <span>Lưu</span>
          </Button>
          <Button className="btn-outline" onClick={handleClose}>
            Đóng
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ModalChonKetQuaDichVu;
