import { FunctionComponent, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { PhanHeTiepDonContext } from "../contexts/PhanHeTiepDonContext";
import { convertNumberPrice } from "../../utils/FormatUtils";

interface ThongTinChiPhiProps {}

const ThongTinChiPhi: FunctionComponent<ThongTinChiPhiProps> = () => {
  const { benhNhanInfo, setBenhNhanInfo, thongTinChiPhi } =
    useContext(PhanHeTiepDonContext);
  return (
    <Row className="info-patient px-0 py-0 spaces min-h-105 mx-0">
      <Col xs="8" className="px-0 d- flex-column justify-content-center">
        <div className="fs-14px line-height-22 spaces mb-5 w-100">
          <span className="d-inline-block spaces W-120 fw-bold">
            Tổng chi phí
          </span>
          <span className="fw-light">
            {thongTinChiPhi?.ttTong
              ? convertNumberPrice(thongTinChiPhi?.ttTong)
              : 0}
          </span>
        </div>
        <div className="fs-14px line-height-22 spaces mb-5 w-100">
          <span className="d-inline-block spaces W-120 fw-bold">BHYT trả</span>
          <span className="fw-light">
            {thongTinChiPhi?.ttBHYT
              ? convertNumberPrice(thongTinChiPhi?.ttBHYT)
              : 0}
          </span>
        </div>
        <div className="fs-14px line-height-22 spaces mb-5 w-100 ">
          <span className="d-inline-block spaces W-120 fw-bold">Bệnh nhân</span>
          <span className="fw-light">
            {thongTinChiPhi?.ttNguoiBenh
              ? convertNumberPrice(thongTinChiPhi?.ttNguoiBenh)
              : 0}
          </span>
        </div>
      </Col>
      <Col xs="4" className="text-center border-start border-secondary px-0">
        <div className="fs-14px line-height-22 spaces mb-10">
          <p className="fw-bold mb-0">Đã thu</p>
          <span className="fw-normal">
            {thongTinChiPhi?.ttDaThu
              ? convertNumberPrice(thongTinChiPhi?.ttDaThu)
              : 0}
          </span>
        </div>
        <div
          className={`fs-14px line-height-22 spaces ${
            thongTinChiPhi?.ttTamUng + thongTinChiPhi?.ttDaThu >
            thongTinChiPhi?.ttNguoiBenh
              ? "text-success"
              : "text-danger"
          }`}
        >
          <p className="fw-bold mb-0">
            {thongTinChiPhi?.ttTamUng + thongTinChiPhi?.ttDaThu >
            thongTinChiPhi?.ttNguoiBenh
              ? "Còn dư"
              : "Còn nợ"}
          </p>
          <span className="fw-normal">
            {thongTinChiPhi?.ttConNo
              ? convertNumberPrice(thongTinChiPhi?.ttConNo)
              : 0}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default ThongTinChiPhi;
