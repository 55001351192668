import React from "react";

type Props = {
    statuses: {
      name: string;
      color: string;
    }[];
    className?: string
};

const ShowStatus = (props: Props) => {
  let { statuses = [], className = '' } = props;

  return (
    <div className={className ? className :`d-flex spaces px-24 py-10 gap-16`}>
      {statuses.map((status: any, index: any) => {
        return (
          <div key={index} className="text-start spaces gap-8">
            <i className="bi bi-circle-fill pe-2" style={{ color: status.color }}></i>
            &nbsp;
            <span>{status.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ShowStatus;
