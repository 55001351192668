import { FC, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import InputSearch from "../InputSearch";
import { getData } from "../../phan-he-tiep-nhan-thanh-toan/services/TiepNhanServices";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  KEY,
  overflowHiddenTable,
  RESPONSE_MESSAGE,
  textSystem,
} from "../../utils/Constant";
import { IConfigTable } from "../../models/OctResponse";
import { handleConvertAddress, handleConvertBirthdate } from "../../utils/AppFunction";
import OctTableCustom from "../table/table-oceantech-ui/OctTableCustom";

interface Props {
  open: boolean;
  handleClose: () => void;
  onSelectedPatient?: (patient: any) => void;
  objectSearchBN?: any;
  setPatientSelectedId?: (id: string) => void;
}

const ModalTimKiemBenhNhan: FC<Props> = (props) => {
  let { open, handleClose, objectSearchBN, setPatientSelectedId } = props;
  const { setIsLoading } = useContext(AppContext);
  const [benhNhanData, setBenhNhanData] = useState();
  const [configTable, setConfigTable] = useState<IConfigTable>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  let [objectSearch, setObjectSearch] = useState<any>({
    ...objectSearchBN,
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: "",
  });
  
  useEffect(() => {
    getListBenhNhan();
  }, [objectSearch.pageIndex, objectSearch.pageSize]);

  const getListBenhNhan = async () => {
    try {
      const { data } = await getData(objectSearch);

      if (CODE.SUCCESS === data?.code) {
        setBenhNhanData(data?.data?.content || []);
        setConfigTable({
          totalPages: data?.data?.totalPages,
          totalElements: data?.data?.totalElements,
          numberOfElements: data?.data?.numberOfElements,
        });
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
        setIsLoading(false);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: any) => {
    setPatientSelectedId?.(data?.id);
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setObjectSearch({
      ...objectSearch,
      keyword: value
    });
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    KEY.ENTER === event.key && getListBenhNhan();
  };

  const TimKiemBenhNhanColumns = [
    {
      name: "STT",
      field: "",
      cellStyle: {
        textAlign: "center",
        ...textSystem
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Mã BN",
      field: "code",
      cellStyle: {
        minWidth: "140px",
        textAlign: "center",
        ...textSystem
      }
    },
    {
      name: "Họ và tên",
      field: "personName",
      cellStyle: {
        minWidth: "200px",
        ...textSystem
      }
    },
    {
      name: "Ngày sinh",
      field: "birthDate",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center",
        ...textSystem
      },
      render: (rowData: any) =>
        handleConvertBirthdate(rowData)
    },
    {
      name: "Giới tính",
      field: "gender",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center",
        ...textSystem
      }
    },
    {
      name: "Địa chỉ",
      field: "administrativeUnit",
      cellStyle: {
        maxWidth: "330px",
        ...textSystem,
        ...overflowHiddenTable
      },
      render: (rowData: any) =>
        handleConvertAddress(rowData)
    },
    {
      name: "Nghề nghiệp",
      field: "occupationName",
      cellStyle: {
        maxWidth: "200px",
        ...textSystem,
        ...overflowHiddenTable
      }
    }
  ];

  return (
    <Modal size="xl" show={open} animation centered>
      <div className="timKiemBenhNhanDatLichHen">
        <Modal.Header className="py-3 header-modal">
          <Modal.Title className="text-pri">Tìm kiếm bệnh nhân</Modal.Title>
          <button className="btn-close" onClick={() => handleClose()}></button>
        </Modal.Header>
        <Modal.Body className="py-16 px-24 spaces">
          <div className="tableTimKiem">
            <InputSearch
              handleChange={handleChange}
              handleSearch={getListBenhNhan}
              handleKeyDown={handleKeyDown}
              placeholder="Tìm kiếm"
              type="text"
            />
            <OctTableCustom
              id="searchPatients"
              className="mt-2"
              data={benhNhanData || []}
              columns={TimKiemBenhNhanColumns}
              searchObject={objectSearch}
              setSearchObject={setObjectSearch}
              fixedColumnsCount={-1}
              notDelete={true}
              notEdit={false}
              noToolbar={true}
              totalPages={configTable.totalPages}
              totalElements={configTable.totalElements}
              numberOfElements={configTable.numberOfElements}
              handleDoubleClick={handleSubmit}
              isShowSelection={false}
              clearToolbar={true}
              height={390}
            />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ModalTimKiemBenhNhan;
