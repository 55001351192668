import React, { createContext, useEffect, useState } from "react";
import { Button, FormCheck, InputGroup, Row } from "react-bootstrap";
import TextValidator from "../../../component/TextValidator";
import ModelThemMoiHopDong from "../../ModelThemMoiHopDong";
import DSHopDong from "../DSHopDong";
import DanhSachBenhNhan from "./DanhSachBenhNhan";
import "./quan-ly-hop-dong/QuanLyHopDong.scss";
import QuanLyHopDongTab from "./quan-ly-hop-dong/QuanLyHopDongTab";
import { KEY } from "../../../utils/Constant";
import LabelRequired from "../../../component/LabelRequired";
import { OctDatePickerCustom } from "@oceantech/oceantech-ui";
import { filterDateList } from "../../constants/PhanHeKhamDoan";

type Props = {
};
export interface QuanLyHopDongContextProps {
  TTHopDong: any;
  setTTHopDong: any;
  TTNhanVien: any;
  setTTNhanVien: any;
  isSearchGK: boolean;
  setIsSearchGK: any;
  isSearchNV: boolean;
  setIsSearchNV: any;
  dataEditNV: any;
  setDataEditNV: any;
  dataEditGK: any;
  setDataEditGK: any;
  isClickOpenEditNV: boolean;
  setIsClickOpenEditNV: any;
  isClickOpenEditGK: boolean;
  setIsClickOpenEditGK: any;
}
const initialContext = {
  TTHopDong: {},
  setTTHopDong: () => { },
  TTNhanVien: {},
  setTTNhanVien: () => { },
  isSearchGK: true,
  setIsSearchGK: () => { },
  isSearchNV: true,
  setIsSearchNV: () => { },
  dataEditNV: {},
  setDataEditNV: () => { },
  dataEditHopHD: {},
  dataEditGK: {},
  setDataEditGK: () => { },
  isClickOpenEditNV: false,
  setIsClickOpenEditNV: () => { },
  isClickOpenEditGK: false,
  setIsClickOpenEditGK: () => { },
}
export const QuanLyHopDongContext = createContext<QuanLyHopDongContextProps>(initialContext)

const QuanLyHopDong = (props: Props) => {
  const [TTHopDong, setTTHopDong] = useState<any>({});
  const [dataEditGK, setDataEditGK] = useState<any>({});
  const [isClickOpenEditNV, setIsClickOpenEditNV] = useState(false);
  const [isClickOpenEditGK, setIsClickOpenEditGK] = useState(false);
  const [dataEditNV, setDataEditNV] = useState<any>({});
  const [TTNhanVien, setTTNhanVien] = useState<any>({});
  const [openDSHopDong, setOpenDSHopDong] = useState(true);
  const [isSearchGK, setIsSearchGK] = useState(true);
  const [isSearchNV, setIsSearchNV] = useState(true);
  const [openAddEditHopDong, setOpenAddEditHopDong] = useState(false);
  const [openQuanLyHopDong, setOpenQuanLyHopDong] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [filter, setFilter] = useState<any>(null);
  const [objectSearch, setObjectSearch] = useState<any>();
  const [typeChecks, setTypeChecks] = useState<any>({
    filterStartDate: true,
    filterEndDate: false,
  });

  useEffect(() => {
    setFilter({ ...objectSearch, ...typeChecks })
  }, [typeChecks]);

  const handleChangeValueInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setObjectSearch({
      ...objectSearch,
      [event.target.name]: event.target.value,
    });
  };

  const handlerOpenHD = (data: any) => {
    setTTHopDong(data);
    setOpenDSHopDong(false);
    setOpenQuanLyHopDong(true)
  }
  const handlerCloseHD = () => {
    setTTHopDong({})
    setTTNhanVien({})
    setOpenDSHopDong(true);
    setOpenQuanLyHopDong(false)
    setOpenAddEditHopDong(false)
  }
  const handleSubmitSearch = () => {
    setFilter({ ...objectSearch })
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === KEY.ENTER) {
      setFilter({ ...objectSearch });
    }
  };

  const handleStartDateChange = (date: string | null) => {
    date && setFilter({ ...filter, startDate: date})
  }

  const handleEndDateChange = (date: string | null) => {
    date && setFilter({ ...filter, endDate: date})
  }

  const handleTypeCheckChange = (item: any, e: any) => {
    if (item?.name === "filterStartDate") {
      setTypeChecks({
        filterStartDate: true,
        filterEndDate: false
      });
      setFilter({ ...filter, filterStartDate: true })
    } else {
      setTypeChecks({
        filterStartDate: false,
        filterEndDate: true
      });
      setFilter({ ...filter, filterEndDate: true })
    }
  };
  
  return (
    <QuanLyHopDongContext.Provider
      value={{
        TTHopDong,
        TTNhanVien,
        setTTNhanVien,
        setTTHopDong,
        isSearchGK,
        setIsSearchGK,
        isSearchNV,
        dataEditNV,
        setDataEditNV,
        setIsSearchNV,
        dataEditGK,
        setDataEditGK,
        isClickOpenEditGK,
        setIsClickOpenEditGK,
        isClickOpenEditNV,
        setIsClickOpenEditNV
      }}
    >
      <div className="reception-list bg-white ">
        {openDSHopDong && (
          <div className="bg-white ">
            <div className=" bg-white">
              <h4 className="text-title border-bottom fw-bold fs-4 spaces mb-0 px-8 py-3">
                Danh sách hợp đồng khám sức khoẻ
              </h4>
            </div>

            <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
              <div className=" bg-white d-flex align-items-center mr-20">
                <Button
                  className="btn-fill  spaces min-w-105 h-29 rounded-end-1"
                  onClick={() => {
                    setOpenAddEditHopDong(true);
                  }}
                >
                  <span>Thêm mới HĐ</span>
                </Button>
              </div>
              <div className="d-flex align-items-center">
                {filterDateList.map((item: any) => (
                  <div key={`inline-radio-${item?.type}`}>
                    <FormCheck
                      inline
                      label={item?.label}
                      name={item?.name}
                      type="radio"
                      checked={typeChecks[item?.name]}
                      className="d-flex align-items-center spaces gap-7"
                      onChange={(e) =>
                        handleTypeCheckChange(item, e)
                      }
                    />
                  </div>
                ))}
              </div>
              <div className="d-flex align-items-center spaces mr-10">
                <LabelRequired className="min-w-65px fw-bold" label="Từ ngày" />
                <OctDatePickerCustom
                  value={filter?.startDate || ""}
                  placeholderText="dd/mm/yyyy"
                  dateFormatOutput="YYYY-MM-DD"
                  setDateValue={handleStartDateChange}
                />
              </div>
              <div className="d-flex align-items-center spaces mr-10">
                <LabelRequired
                  className="min-w-65px fw-bold"
                  label="Đến ngày"
                />
                <OctDatePickerCustom
                  value={filter?.endDate || ""}
                  placeholderText="dd/mm/yyyy"
                  dateFormatOutput="YYYY-MM-DD"
                  setDateValue={handleEndDateChange}
                />
              </div>
              <div className="d-flex flex-grow-1">
                <InputGroup className="spaces h-29 px-0">
                  <TextValidator
                    className="spaces h-29 min-w-180 border-radius-left-right-none"
                    name="name"
                    placeholder="Tìm kiếm"
                    value={objectSearch?.name || ""}
                    onChange={handleChangeValueInput}
                    onKeyDown={handleKeyPress}
                  />
                  <Button
                    className="btn-fill spaces min-w-105 h-29 "
                    onClick={handleSubmitSearch}
                  >
                    <span>Tìm kiếm</span>
                    {/* <i className="bi bi-caret-down-fill ms-2 fs-7" /> */}
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
        )}

        <div className="h-100 pl-3 bg-white">
          {openDSHopDong && (
            <DSHopDong
              show={openDSHopDong}
              setOpenAddEditHopDong={() => setOpenAddEditHopDong(true)}
              isResearch={!openAddEditHopDong}
              filter={filter}
              isColumnsActions={true}
              onHandlerOpenThongTinHĐ={handlerOpenHD}
            />
          )}

          {openQuanLyHopDong && (
            <Row className="h-100 bg-gray">
              <div className="flex-1 flex flex-column pe-0 spaces width-27">
                <DanhSachBenhNhan
                  handlerCloseHD={handlerCloseHD}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  setFilter={setFilter}
                />
              </div>
              <div className="pl-0 pr-0 ml-pt-0_6 spaces width-72 bg-white">
                <QuanLyHopDongTab />
              </div>
            </Row>
          )}
        </div>
        <div className="flex-1 flex flex-column">
          <div className="pt-3 h-100">
            {openAddEditHopDong && (
              <ModelThemMoiHopDong
                show={openAddEditHopDong}
                onHide={() => {
                  setTTHopDong({});
                  setOpenAddEditHopDong(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </QuanLyHopDongContext.Provider>
  );
};

export default QuanLyHopDong;
