import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import DanhSachPhieu from "../../../component/DanhSachPhieu";
import LabelRequired from "../../../component/LabelRequired";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { CODE, KEY_DS_DANH_MUC_TIEP_DON, LIST_COLOR_TRANG_THAI_PHIEU, ORDER_TYPE_ID, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { formatDateAdvanceToString } from "../../../utils/FormatUtils";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import { WfConstTracker, WfConstTrackerClinical } from "../../../workflows/Constant";
import WfButton from "../../../workflows/components/WfButton";
import "../../PhanHeKhamBenh.scss";
import { columnsDSCdhaTdcn } from "../../columns/tab-cdha-tdcn/ColumnTabCdhaTdcn";
import DynamicComponentKB from "../../components/DynamicComponentKB";
import { CODE_CONTEXT_TAB_XET_NGHIEM } from "../../constants/ContextConstants";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { deleteDSChiDinhDV, getDSDVDaChiDinh, getDSNhomDVDaChiDinh } from "../../services/ChiDinhDVService";
import { fetchGuiPhieu, fetchHuyGuiPhieu, getStatusDesc } from "../../services/KhamBenhService";
import CheckIcon from "../../components/CheckIcon";
import ModalThucHienCDHA from "../../../phan-he-cdha-va-tdcn/components/modals/ModalThucHienCDHA";
import ButtonInPhieu from "../../../component/button-in-phieu";
import { inPhieuChiDinhCLS } from "../../services/PhanHeTiepDonServer";
import ShowStatus from "../../../component/ShowStatus";

export type KhamBenh = {
  thongTinKhamBenh?: any;
  setThongTinKhamBenh: ((data: any) => void) | undefined;
};

interface Iprops {
  danhSachPhieu?: boolean;
}

export const TabCdhaTdcn = ({ danhSachPhieu = true }: Iprops) => {
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } = useContext(PhanHeTiepDonContext);
  let { visit, isConclusion, patient } = benhNhanInfo?.thongTinKhamBenh || {};
  const [dsXetNghiemDetail, setDsXetNghiemDetail] = useState<any[]>([]);
  const [dsPhieu, setDsPhieu] = useState<any[]>([]);
  const [selectedPhieu, setSelectedPhieu] = useState<any>([]);
  const [openPhieuIn, setOpenPhieuIn] = useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>();
  const [idTable, setIdTable] = useState("");
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [contextClientX, setContextClientX] = useState<number>(0);
  const [listICD10, setListICD10] = useState<any>([]);
  const [dynamicData, setDynamicData] = useState<any>(null);
  const [resultData, setResultcData] = useState<any>(null);
  const [shouldOpenResultModal, setShouldOpenResultModal] = useState<boolean>(false);
  const encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis;
  const [statusDesc, setStatusDesc] = useState<any>([]);

  const handleGetStatusDesc = async () => {
    try {
      let { data } = await getStatusDesc(WfConstTracker.RISPACS);
      if (data?.code === CODE.SUCCESS) {
        setStatusDesc(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetStatusDesc();
  }, []);
  const actionChanged = useCallback(
    async (currStatusCode: string | undefined) => {
      try {
        let res;
        switch (currStatusCode) {
          case "order_group_cancel":
            res = await fetchHuyGuiPhieu(selectedPhieu?.id);
            break;
            case "order_group_submit":
            res = await fetchGuiPhieu(selectedPhieu?.id);
            break;
          default:
            res = null;
            break;
        }
        if (CODE.SUCCESS === res?.data?.code) {
          statusChangedEventFunc?.();
          handleSelectPhieu(selectedPhieu);
          setBenhNhanInfo({
            ...benhNhanInfo,
            shouldUpdate: !benhNhanInfo?.shouldUpdate,
          });
          toast.success("Thành công");
        } else {
          toast.warning(res?.data?.message || RESPONSE_MESSAGE.ERROR);
        }
      } catch (error) {
        toast.warning(RESPONSE_MESSAGE.ERROR);
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benhNhanInfo]
  );

  const handleContextMenu = (e: any, row: any, code?: string) => {
    setRowSelected(row);
    setIdTable(code as string);
    e.preventDefault();
    setContextClientX(e.clientX);

    const heightDropList = document.getElementById("drop-list")?.clientHeight || 0;
    const isOnRight = (window.innerWidth - e.clientX) < 200;
    const newX = isOnRight ? e.clientX - 200 : e.clientX;
    const isBottom = (window.innerHeight - e.clientY) < 200;
    const newY = isBottom ? e.clientY - heightDropList : e.clientY;

    setContextMenu({ x: newX, y: newY });
  };

  useEffect(() => {
    !dsPhieu.length && setDsXetNghiemDetail([]);
  }, [benhNhanInfo, dsPhieu]);

  const handleSelectPhieu = async (dataPhieu: any) => {
    try {
      setSelectedPhieu(dataPhieu);
      let { data } = await getDSDVDaChiDinh({
        orderGroupId: dataPhieu?.id,
        type: ORDER_TYPE_ID.XET_NGHIEM
      });
      if (CODE.SUCCESS === data?.code) {
        const newData = data?.data?.map((item: any) => {
          return {
            ...item,
            terms: item?.terms?.map((term: any) => {
              if (term?.subs) {
                return {
                  ...term,
                  name: <CheckIcon data={term} color={item?.color} className="ml-2" />,
                  subs: term?.subs?.map((sub: any) => {
                    return {
                      ...sub,
                      name: <CheckIcon color={item?.color} data={sub} />
                    }
                  })
                }
              } else {
                return {
                  ...term,
                  name: <CheckIcon color={item?.color} data={term} />
                }
              }
            })
          }
        });
        setDsXetNghiemDetail(newData || []);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleConfirmDelete = async () => {
    try {
      await deleteDSChiDinhDV(selectedPhieu?.id);
      setBenhNhanInfo({
        ...benhNhanInfo,
        shouldUpdate: !benhNhanInfo?.shouldUpdate,
      });
      statusChangedEventFunc?.();
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  }

  const updataData = async () => {
    try {
      let { data } = await getDSNhomDVDaChiDinh({
        visitId: visit?.id,
        type: ORDER_TYPE_ID.CDHA
      });
      if (CODE.SUCCESS === data?.code) {
        let dsPhieuConverted = data?.data?.map((item: any) => {
          return {
            ...item,
            date: moment(item?.date).format("DD/MM/YYYY"),
          };
        });
        setDsPhieu(dsPhieuConverted || []);
        setSelectedPhieu(null);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  }
  
  const handleClickWfButton = (data: any) => {
    setDynamicData(data);
  };

  useEffect(() => {
    if (visit?.id) {
      updataData();
    }
  }, [benhNhanInfo?.shouldUpdate, visit?.id]);

  const handleViewResult = (data: any) => {
    if (data?.isResultReleased) {
      setShouldOpenResultModal(true);
      setResultcData(data?.obs?.attributeValue);
    } else {
      toast.warning("Chưa trả kết quả");
    }
  };

  useEffect(() => {
    handleGetListICD10();
  }, []);

  useEffect(() => {
    let orderGroupId = selectedPhieu?.id;
    setBenhNhanInfo({
      ...benhNhanInfo,
      thongTinKhamBenh: {
        ...benhNhanInfo?.thongTinKhamBenh,
        orderGroupId
      }
    });
  }, [selectedPhieu]);

  return (
    <Row className="h-100">
      <div>
        <div className="pt-4 bg-white">
          <Row className="mx-0">
            <Col xs={4} className="d-flex align-items-center mb-3">
              <LabelRequired label="Mã phiếu" className="min-w-100px" />
              <span className="fw-light text-truncate">{selectedPhieu?.code || "..."}</span>            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
              <span className="fw-light text-truncate">{formatDateAdvanceToString(selectedPhieu?.indicationTime)}</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Người chỉ định" className="min-w-100px" />
              <span className="fw-light">{selectedPhieu?.indicationPerson}</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
              <span className="fw-light text-truncate">{selectedPhieu?.requestDeptName || "..."}</span>
            </Col>
            <Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Chẩn đoán" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {(isConclusion && encounterDiagnosis?.icdXPrimary)
                  ? listICD10?.find((item: any) => item?.code === encounterDiagnosis?.icdXPrimary)?.name
                  : "..."}
              </span>
            </Col>
          </Row>
        </div>
        <div className="bg-white spaces pt-2 mb-6">
          <DanhSachPhieu
            className="mb-2"
            handleSelectRowData={handleSelectPhieu}
            dsPhieu={dsPhieu as []}
            handleContextMenu={(e, row) => handleContextMenu(e, row, CODE_CONTEXT_TAB_XET_NGHIEM.DS_PHIEU)}
            listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
            isGetFirstData
          />
          <TableCollapseCustom
            columns={columnsDSCdhaTdcn}
            data={dsXetNghiemDetail || []}
            childrenField="subs"
            className={`overflow-auto spaces h-calc-vh-447`}
            handleDoubleClick={handleViewResult}
          />
        </div>
        <div className="d-flex justify-content-between p-2 gap-3 bg-white">
          <div className="d-flex">
            {(visit?.id && patient?.id) && (
              <>
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu chỉ định"
                  fetchExport={inPhieuChiDinhCLS}
                  params={{
                    visitId: visit?.id,
                    patientId: patient?.id,
                  }}
                />
                <Button
                  className="btn-fill spaces mx-3"
                  onClick={() => setOpenPhieuIn({ phieuKetQuaXetNghiem: true })}
                >
                  Phiếu kết quả
                </Button>
              </>
            )}
            {(selectedPhieu && !isConclusion) && (
              <WfButton tracker={WfConstTrackerClinical.ORDER_GROUP} objectId={selectedPhieu?.id} additionalFunc={actionChanged} handleClick={handleClickWfButton}></WfButton>
            )}
          </div>

          <div className="spaces w-60">
            <Stack
              direction="horizontal"
              gap={3}
              className="justify-content-end"
            >
              <ShowStatus
                className="d-flex spaces gap-8"
                statuses={statusDesc}
              />
            </Stack>
          </div>
        </div>
      </div>
      {dynamicData && <DynamicComponentKB data={dynamicData} setData={setDynamicData} onConfirm={handleConfirmDelete} />}
      {shouldOpenResultModal && <ModalThucHienCDHA
        handleSubmitForm={() => { }}
        handleClose={() => setShouldOpenResultModal(false)}
        handleTraKetQua={() => { }}
        infoBenhNhan={benhNhanInfo?.thongTinKhamBenh}
        resultData={resultData}
        isViewModal
      />}
    </Row>
  );
};

export default TabCdhaTdcn;
