import { Column } from "react-table";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import TableGroupSelection from "../../../phan-he-kham-benh/components/tab-kham-benh/modal-chon-ket-qua-dich-vu/components/TableGroupSelection";

type Props = {
  dataKetQuaCLS: any;
  handleDoubleClick?: () => void;
};

const BangDsKetQuaCLS = (props: Props) => {
  let { dataKetQuaCLS, handleDoubleClick = () => {} } = props;
  const columnsDSKetQuaCLD: Column<any>[] = [
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="STT"
          className="p-table text-center min-w-50px"
        />
      ),
      accessor: "stt",
      Cell: ({ ...props }) => {
        return (
          <TableCustomCell
            data={props?.row?.original?.index ? String(props?.row?.index + 1) : ""}
            className="text-center fs-14"
          />
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Dịch vụ"
          className="p-table text-center min-w-250px"
        />
      ),
      accessor: "tenDichVu",
      Cell: ({ row, cell }) => (
        <div
          style={{
            paddingLeft: row.depth * 25,
          }}
        >
          {row.canExpand ? (
            <div className="d-flex align-items-center">
              <span
                className="spaces pe-8 pr-8"
                {...row.getToggleRowExpandedProps()}
              >
                {row.isExpanded ? (
                  <i className="las la-angle-down"></i>
                ) : (
                  <i className="las la-angle-right"></i>
                )}
              </span>
              <span>{cell.value}</span>
            </div>
          ) : (
            <div className="d-flex align-items-center spaces pl-21">
              <span>{cell.value}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader
          tableProps={props}
          title="Kết quả"
          className="p-table text-center min-w-150px"
        />
      ),
      accessor: "ketQua",
    },
  ];

  return (
    <>
      <TableGroupSelection
        data={[...dataKetQuaCLS] || []}
        containerClassName="overflow-auto spaces h-calc-vh-484"
        onSelect={() => {}}
        columns={columnsDSKetQuaCLD}
      />
    </>
  );
};

export default BangDsKetQuaCLS;
