import React from "react";
// import { useIntl } from 'react-intl';
import { Col, Row } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import InputSearch from "../../InputSearch";

function ActionTableTab(props: any) {
  // const intl = useIntl();

  const {
    title,
    buttonAdd,
    buttonExportExcel,
    handleOpenDialog,
    handleExport,
    selectedRows,
    handleCheckBoxAll,
    notDelete,
    handleShowConfirmDialog,
    handleChangeValueInput,
    isSearchable,
    handleSearch,
    searchObject,
    setSearchObject,
  } = props;
  const hasSelectedRow = selectedRows?.length > 0;
  return (
    <Row>
      <Col
        sm={4}
        xl={6}
        xxl={hasSelectedRow ? 7 : 9}
        className="spaces fs-18 text-header-table fw-600 title-action-tab"
      >
        {title}
      </Col>
      <Col
        sm={8}
        xl={6}
        xxl={hasSelectedRow ? 5 : 3}
        className="d-flex align-items-center flex-end"
      >
        {hasSelectedRow && (
          <>
            <span className="spaces mr-8">
              {"Đã chọn"}:
              <strong className="ps-2">
                {selectedRows ? selectedRows?.length : 0}
              </strong>
            </span>
            <span
              className="fw-bold text-warning cursor-pointer "
              onClick={() => handleCheckBoxAll(false)}
            >
              {"Bỏ chọn"}
            </span>
            {!notDelete && (
              <div
                className="delete-box cursor-pointer spaces mr-8"
                onClick={handleShowConfirmDialog}
              >
                <i className="bi bi-trash fs-4 text-danger px-4"></i>
                <span className="fw-bold text-danger">{"Xóa"}</span>
              </div>
            )}
          </>
        )}
        {isSearchable && (
          <InputSearch
            name="keyword"
            placeholder="Tìm kiếm"
            type={"text"}
            handleChange={handleChangeValueInput}
            value={searchObject.keyword}
            handleSearch={handleSearch}
          />
        )}
        {buttonAdd && (
          <button
            className="spaces button-primary flex flex-middle ml-8"
            onClick={(e: any) => {
              e.preventDefault();
              handleOpenDialog();
            }}
          >
            <i className="spaces bi bi-plus fs-20 white"></i>
            <p className="spaces fs-14 m-0 w-max-content">Thêm mới</p>
          </button>
        )}

        {buttonExportExcel && (
          <button
            className="flex flex-middle table-btn outline spaces ml-8"
            onClick={handleExport}
          >
            <KTSVG path="/media/icons/export-excel.svg" /> {"Xuất Excel"}
          </button>
        )}
      </Col>
    </Row>
  );
}

export default ActionTableTab;
