
const CheckIcon = ({ data, className, color }: any) => {
    let isResultReleased = data?.isResultReleased;
    return (
        <div className='d-flex'>
            <div
                className={`${isResultReleased ? "background-color-blue" : "background-color-gray"} icon-circle spaces min-w-15 mr-10 ${className || ""}`}
                style={{ backgroundColor: color }}
            >
               
            </div>
            <div>{data?.name}</div>
        </div>
    )
};

export default CheckIcon;