import { useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import ContextMenu from "../../../component/ContextMenuV3";
import FilterSidebar from "../../../component/filter-sidebar/FilterSidebar";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { IContextMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { getPaymentDetail } from "../../../phan-he-vien-phi/services/PhanHeVienPhiServices";
import {
  CODE,
  RESPONSE_MESSAGE,
  SEARCH_OBJECT_MAX_SIZE,
  SELECTION_MODE,
} from "../../../utils/Constant";
import {
  formatDateToYYYYMMDD,
  formatTrangThaiBenhNhan,
} from "../../../utils/FormatUtils";
import WfStatusSummary from "../../../workflows/components/WfStatusSummary";
import { WfSummaryStatusRes } from "../../../workflows/models/WfWorkflows";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { IBenhNhan } from "../../models/DSBenhNhanKhamBenhModels";
import {
  cancelMedicalExam,
  getDSKhamBenh,
  getThongTinKhamBenh,
  startMedicalExam,
} from "../../services/PhanHeTiepDonServer";
import { updateWfWorkflows } from "../../../workflows/services/WfWorkflowsService";
import { WfConstTracker } from "../../../workflows/Constant";
import ModalKhamKetHop from "../../modals/modal-kham-ket-hop/ModalKhamKetHop";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";

const TableDsBenhNhanKhamBenh = (props: any) => {
  const {
    setBenhNhanInfo,
    benhNhanInfo,
    benhNhanList,
    setBenhNhanList,
    setThongTinChiPhi,
    statusChangedEventFunc,
  } = useContext(PhanHeTiepDonContext);
  const { filter, setFilter } = props;
  const { setIsLoading } = useContext(AppContext);
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [selectedBenhNhan, setSelectedBenhNhan] = useState<any>();
  const [summaryStatus, setSummaryStatus] = useState<WfSummaryStatusRes[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isAllowCancel, setIsAllowCancel] = useState<boolean>(false);
  const [shouldOpenKhamKetHop, setShouldOpenKhamKetHop] =
    useState<boolean>(false);

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
    setSelectedRow(row?.original);
    setIsAllowCancel(row?.original?.isAllowCancel);
  };

  const updatePageData = async (filter?: any) => {
    setIsLoading(true);
    const searchObject = SEARCH_OBJECT_MAX_SIZE;
    const DEFAULT_FORMAL = 0;
    try {
      let { data } = await getDSKhamBenh({
        ...searchObject,
        keyword: filter?.keyword || null,
        filter: filter?.formal?.id || DEFAULT_FORMAL,
        sortDirection: filter?.order?.id || null,
        fromDate: formatDateToYYYYMMDD(filter?.period?.fromDate) || null,
        toDate: formatDateToYYYYMMDD(filter?.period?.toDate) || null,
        color: filter?.status?.color || null,
      });
      if (data?.code === CODE.SUCCESS) {
        let newListBN = data?.data?.medicalExamBrief;
        let newListStatus = data?.data?.statusSummary;
        setBenhNhanList?.(newListBN || []);
        setSummaryStatus(newListStatus || []);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    filter && updatePageData(filter);
  }, [props.statusChangedEvent]);

  useEffect(() => {
    selectedBenhNhan && handleGetThongTinKhamBenh(selectedBenhNhan);
  }, [selectedBenhNhan, benhNhanInfo?.shouldUpdate]);

  const handleGetThongTinKhamBenh = async (dataBenhNhan: any) => {
    try {
      let visitId = dataBenhNhan?.visitId;
      let orderId = dataBenhNhan?.orderId;

      setIsLoading(true);
      if (visitId && orderId) {
        let { data } = await getThongTinKhamBenh({
          visitId,
          orderId,
        });
        let { data: dataChiPhi } = await getPaymentDetail(visitId);
        if (CODE.SUCCESS === dataChiPhi?.code) {
          setThongTinChiPhi({
            ...dataChiPhi?.data,
            ttDiscount:
              dataChiPhi?.data?.ttDiscountInv +
              dataChiPhi?.data?.ttDiscountPercent,
          });
        }
        if (CODE.SUCCESS === data?.code) {
          setBenhNhanInfo({
            ...benhNhanInfo,
            isKhamBenh: false,
            isExamined: false,
            thongTinKhamBenh: {
              ...benhNhanInfo?.thongTinKhamBenh,
              ...data?.data,
            },
          });
        }
      } else {
        setBenhNhanInfo({
          ...benhNhanInfo,
          isKhamBenh: false,
          isExamined: false,
          thongTinKhamBenh: {},
        });
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedBenhNhan = async (benhNhans: IBenhNhan[]) => {
    setSelectedBenhNhan(benhNhans[0]);
  };

  const handleBatDauKham = async (e: any, rowData: any) => {
    try {
      e.stopPropagation();
      await updateWfWorkflows(WfConstTracker.CLINICAL, {
        objectId: rowData?.orderId,
        status: rowData?.newStatusId
      });
      let { data } = await startMedicalExam({
        patientId: rowData?.patientId,
        visitId: rowData?.visitId,
        orderId: rowData?.orderId,
      });
      setBenhNhanInfo({
        ...benhNhanInfo,
        thongTinKhamBenh: {
          ...benhNhanInfo?.thongTinKhamBenh,
          isExamined: data.data?.isExamined,
          encounter: data.data?.encounter,
          encounterDiagnosis: data.data?.encounterDiagnosis,
        },
      });
      await handleSelectedBenhNhan([rowData]);
      statusChangedEventFunc?.();
    } catch (error) {
      console.error(error);
    }
  }

  const DsBenhNhanColumn: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={""}
          className=" text-center text-light spaces W-70"
        />
      ),
      id: "Thao tác",
      Cell: ({ ...props }) => {
        let rowData = props?.data[props?.row?.index];
        return (
          <div className="d-flex justify-content-center">
            <>
              {!rowData?.isMedicalExamStarted && (
                <CustomIconButton
                  variant="edit"
                  title="Bắt đầu khám"
                  onClick={(e) => handleBatDauKham(e, rowData)}
                >
                  <i className="bi bi-plus-square text-navy"></i>
                </CustomIconButton>
              )}

              {rowData?.isAllowCancel && (
                <CustomIconButton
                  variant="edit"
                  title="Hủy khám"
                  onClick={(e) => handleHuyKham(e, rowData)}
                >
                  <i className="bi bi-dash-square text-danger"></i>
                </CustomIconButton>
             )}

              {(rowData?.isMedicalExamStarted && !rowData?.isVisitStopped) && (
                <CustomIconButton
                  variant="delete"
                  title="Khám kết hợp"
                  onClick={() => setShouldOpenKhamKetHop(true)}
                >
                  <i className="bi bi-plus-square-fill text-success"></i>
                </CustomIconButton>
             )}

              {rowData?.isVisitStopped && (
                <CustomIconButton
                  variant="delete"
                  title="Mở lại bệnh án"
                  onClick={() => {}}
                >
                  <i className="bi bi-clipboard2-pulse-fill text-success"></i>
                </CustomIconButton>
              )}
            </>
          </div>
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"TT"}
          className=" text-center text-light min-w-30px "
        />
      ),
      id: "TT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={formatTrangThaiBenhNhan(props?.data[props?.row?.index]?.color)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className="text-center text-light max-w-35 spaces "
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={String(props?.row?.index + 1)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Tên bệnh nhân"}
          className="text-center text-light min-w-175px "
        />
      ),
      id: "tenBN",
      Cell: ({ ...props }) => (
        <div className="text-system ">
          <div className="flex m-0">
            <span className="text-uppercase">
              {props?.data[props?.row?.index]?.name || ""}
            </span>
            {/* {props?.data[props?.row?.index]?.uuTien===UU_TIEN.uuTien.code?<span><i className="bi bi-check-circle-fill text-cyan ms-2"></i></span>:""} */}
          </div>
          <div className="flex m-0">
            <span className="text-uppercase fw-semibold">
              {props?.data[props?.row?.index]?.code || ""}
            </span>
            <span className="px-1"> - </span>
            <span>{props?.data[props?.row?.index]?.objectTypeName || ""}</span>
            <span className="px-1"> - </span>
            <span>{props?.data[props?.row?.index]?.gender || ""}</span>
          </div>
        </div>
      ),
    },
  ];

  const CODE_MENU = {
    GOI_BN: "goiBN",
    HUY_KHAM: "huyKham",
    KHAM_KET_HOP: "khamKetHop",
  };

  const dropListDsKhamBenh: IContextMenu[] = [
    {
      code: CODE_MENU.GOI_BN,
      name: "Gọi BN",
    },
    ...(isAllowCancel
      ? [
          {
            code: CODE_MENU.HUY_KHAM,
            name: "Hủy khám",
          },
        ]
      : []),
    {
      code: CODE_MENU.KHAM_KET_HOP,
      name: "Khám kết hợp",
    },
  ];
  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.GOI_BN]: () => {},
      [CODE_MENU.HUY_KHAM]: () => {},
      [CODE_MENU.KHAM_KET_HOP]: () => setShouldOpenKhamKetHop(true),
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };

  const handleHuyKham = async (e: any, rowData: any) => {
    try {
      e.stopPropagation();
      setIsLoading(true);
      const OLD_STATUS = 8; // Trạng thái chưa bắt đầu khám
      await updateWfWorkflows(WfConstTracker.CLINICAL, {
        objectId: rowData?.orderId,
        status: OLD_STATUS,
      });
      await cancelMedicalExam(rowData?.orderId);
      await handleSelectedBenhNhan([rowData]);
      statusChangedEventFunc?.();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const doSearch = (data: any) => {
    setFilter(data);
    updatePageData(data);
  };

  const handleSearchByStatus = (status: WfSummaryStatusRes) => {
    let dataFilter = { ...filter, status };
    setFilter(dataFilter);
    updatePageData(dataFilter);
  };

  const LIST_FORMAL = [
    {
      id: 4,
      value: "stt",
      name: "STT",
    },
    {
      id: 2,
      value: "hoTen",
      name: "Họ tên",
    },
    {
      id: 3,
      value: "tuoi",
      name: "Tuổi",
    },
    {
      id: 1,
      value: "maBenhNhan",
      name: "Mã BN",
    },
  ];

  return (
    <>
      <FilterSidebar
        title="Danh sách bệnh nhân"
        formalList={LIST_FORMAL}
        onSearch={doSearch}
        listMenuItem={[]}
        handleSelectOption={() => {}}
      />
      <div className="d-flex flex-column justify-content-between h-100 pt-3">
        <div className="spaces h-calc-vh-245">
          <TableCustom
            data={benhNhanList || []}
            columns={DsBenhNhanColumn}
            selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
            getSelectedRowsData={handleSelectedBenhNhan}
            verticalScroll={true}
            // handleContextMenu={handleContextMenu}
            rowSelected={selectedBenhNhan}
            rowKey="orderId"
          />
        </div>
        <WfStatusSummary
          summaryStatus={summaryStatus}
          onClick={handleSearchByStatus}
        />
        {/* <div className="d-flex justify-content-center spaces py-16 px-12 position-absolute bottom-15 w-100 gap-16">
      </div> */}
        {/* {openModalThongTinKhamBenh && <ModalThongTinKhamBenh handleClose={()=>setOpenModalThongTinKhamBenh(false)}/>} */}

        {contextMenu && (
          <ContextMenu
            handleClickOptionContextMenu={handleClickOptionContextMenu}
            handleCloseMenu={() => setContextMenu(null)}
            data={dropListDsKhamBenh}
            target={contextMenu}
          />
        )}
        {shouldOpenKhamKetHop && (
          <ModalKhamKetHop
            open={shouldOpenKhamKetHop}
            handleClose={() => {
              setShouldOpenKhamKetHop(false);
            }}
            patientSelectedRow={selectedBenhNhan}
          />
        )}
      </div>
    </>
  );
};

export default TableDsBenhNhanKhamBenh;
