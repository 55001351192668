// Import các thư viện cần thiết
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import LabelRequired from "../../../component/LabelRequired";
import "../../PhanHeKhamBenh.scss";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { useFormik, Formik, useFormikContext } from "formik";
import { ObjectSelectAutocomplete } from "../../../phan-he-tiep-nhan-thanh-toan/models/PhanHeTiepNhanModel";
import {
  CODE,
  KEY_DS_DANH_MUC_TIEP_DON,
  PATH_NAME,
  VARIABLE_STRING,
} from "../../../utils/Constant";
import { useLocation } from "react-router-dom";
import ChuyenPhongKhamModal from "../../modals/modal-tab-xu-tri/ModalChuyenPhongKham";
import TextField from "../../../component/TextField";
import ModalICD10 from "../../components/modal-icd10/ModalICD10";
import {
  getDataXuTriService,
  updateXuTri,
} from "../../services/KhamBenhService";
import { toast } from "react-toastify";
import WfButton from "../../../workflows/components/WfButton";
import { WfConstTracker } from "../../../workflows/Constant";
import { MEDICAL_CONCLUSION_MODALITY } from "../../constants/phan-he-kham-benh/ConstantPhanHeKhamBenh";
import ModalChuyenTuyenVien from "../../modals/modal-tab-xu-tri/ModalChuyenTuyenVien";
import ModalCapToaChoVe from "../../modals/modal-tab-xu-tri/ModalCapToaChoVe";
import DynamicModal from "../../modals/modal-tab-xu-tri/DynamicModal";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import { formatDateDTO } from "../../../utils/FormatUtils";
import AutocompleteObjectV2 from "../../../component/AutocompleteObjectV2";

export type KhamBenh = {
  thongTinKhamBenh?: any;
  setThongTinKhamBenh: ((data: any) => void) | undefined;
};

type Props = {
  handleSubmit: () => any;
  dataInfor: any;
  setDataInfor: (data: any) => void;
  listHTXT: any;
  listKQ: any;
  setListHTXT: (data: any) => void;
  setModalityDetails: (data: any) => void;
  modalityDetails: any;
  listICD10: any;
};

export const TabXuTri = (props: Props) => {
  let {
    handleSubmit,
    dataInfor,
    setDataInfor,
    listHTXT,
    listKQ,
    setModalityDetails,
    modalityDetails,
    listICD10,
  } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

  let encounterDiagnosisValues = values?.encounterDiagnosis;

  const { pathname } = useLocation();

  const [nameComp, setNameComp] = useState<string | undefined>("");

  const [icd10Field, setICD10Field] = useState<string>("");
  const [selectionModeICD10, setSelectionModeICD10] = useState<
    "checkbox" | "radio"
  >();
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [shouldOpenModalYHCT, setShouldOpenModalYHCT] =
    useState<boolean>(false);

  const handleSelectOption = (
    option: ObjectSelectAutocomplete,
    name: string
  ) => {
    setFieldValue(name, option);
    if (!option) return;
    setNameComp(option?.id);
  };

  const handleCloseModal = () => {
    setNameComp("");
  };

  const handleSubmitModal = (values: any) => {
    setModalityDetails({
      ...modalityDetails,
      ...values
    });
    setDataInfor({
      ...dataInfor,
      doctorComment: values.doctorComment
    });
    handleCloseModal();
  };

  const compArray = {
    [MEDICAL_CONCLUSION_MODALITY.RA_VIEN.id]: "",
    [MEDICAL_CONCLUSION_MODALITY.CHUYEN_TUYEN.id]: (
      <ModalChuyenTuyenVien
        open={true}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={(values: any) => handleSubmitModal(values)}
        modalityDetails={modalityDetails}
        setModalityDetails={setModalityDetails}
      />
    ),
    [MEDICAL_CONCLUSION_MODALITY.XIN_RA.id]: "",
    [MEDICAL_CONCLUSION_MODALITY.TRON_VIEN.id]: "",
    [MEDICAL_CONCLUSION_MODALITY.CHUYEN_PHONG.id]: (
      <ChuyenPhongKhamModal
        open={true}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={(values: any) => handleSubmitModal(values)}
        modalityDetails={modalityDetails}
        setModalityDetails={setModalityDetails}
      />
    ),
    [MEDICAL_CONCLUSION_MODALITY.CAP_TOA.id]: (
      <ModalCapToaChoVe
        open={true}
        handleCloseModal={() => {
          handleCloseModal();
        }}
        handleSubmit={handleSubmitModal}
        benhNhanInfo={{
          visitId: benhNhanInfo?.thongTinKhamBenh?.visit?.id
        }}
      />
    ),
  };

  const handleChangeEncounterDiagnosis = (name: string, value: any) => {
    setFieldValue("encounterDiagnosis", {
      ...encounterDiagnosisValues,
      [name]: value,
    });
  };

  const handleChangeSelect = (data: any) => {
    switch (icd10Field) {
      case "icdXPrimary":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        setDataInfor({
          ...dataInfor,
          icdXPrimary: data,
        });
        break;
      case "traditionalMedicinePrimary":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        setDataInfor({
          ...dataInfor,
          traditionalMedicinePrimary: data,
        });
        break;
      case "comorbidity":
        let comorbidity = data?.map((item: any) => item?.name).join("; ");
        handleChangeEncounterDiagnosis(`${icd10Field}`, comorbidity || "");
        setDataInfor({
          ...dataInfor,
          comorbidity: comorbidity,
        });
        break;
      case "traditionalMedicineComorbidity":
        let traditionalMedicineComorbidity = data
          ?.map((item: any) => item?.name)
          .join("; ");
        handleChangeEncounterDiagnosis(
          `${icd10Field}`,
          traditionalMedicineComorbidity || ""
        );
        setDataInfor({
          ...dataInfor,
          traditionalMedicineComorbidity: traditionalMedicineComorbidity,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Form>
      <div
        className={`spaces h-calc-vh-${
          PATH_NAME.KHAM_BENH === pathname ? "133" : "169"
        } border-bottom p-7 bg-white mb-6`}
      >
        <Row className="mb-1 pt-5">
          <Col xs={4} className="spaces mt-1">
            <div className="mb-3">
              <div className="d-flex justify-content-between mb-1">
                <LabelRequired label="Chẩn đoán xác định" />
                {!dataInfor?.isVisitStopped && (
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <u className="color-17A2B8">Chọn chẩn đoán</u>
                  </a>
                )}
              </div>

              <TextField
                as="textarea"
                rows={2}
                name="finalDiagnosis"
                value={dataInfor?.finalDiagnosis || ""}
                disabled={dataInfor?.isVisitStopped}
                onChange={(e: any) => {
                  setDataInfor({
                    ...dataInfor,
                    finalDiagnosis: e.target.value,
                  });
                  setFieldValue("finalDiagnosis", e.target.value);
                }}
              />
            </div>

            <div className="mb-2">
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Nguyên nhân - ICD10"
                  className="min-w-150px"
                />
              </div>
              <TextField
                as="textarea"
                rows={2}
                name="icdXCause"
                disabled={dataInfor?.isVisitStopped}
                value={dataInfor?.icdXCause || ""}
                onChange={(e: any) => {
                  setDataInfor({
                    ...dataInfor,
                    icdXCause: e.target.value,
                  });
                  setFieldValue("icdXCause", e.target.value);
                }}
              />
            </div>

            <div className="d-flex gap-2 mb-2">
              <div className="flex-1 w-49">
                <div className="d-flex">
                  <LabelRequired label="Thời gian xử trí" />
                </div>
                <TextField
                  type="datetime-local"
                  className=""
                  name="medicalConclusionDate"
                  disabled={dataInfor?.isVisitStopped}
                  value={dataInfor?.medicalConclusionDate || ""}
                  onChange={(e: any) => {
                    setDataInfor({
                      ...dataInfor,
                      medicalConclusionDate: e.target.value,
                    });
                    setFieldValue("medicalConclusionDate", e.target.value);
                  }}
                />
              </div>
              <div className="flex-1">
                <div className="d-flex">
                  <LabelRequired label="Kết quả điều trị" />
                </div>
                <AutocompleteObjectV2
                  options={listKQ || []}
                  name="medicalConclusion"
                  value={dataInfor?.medicalConclusion}
                  isDisabled={dataInfor?.isVisitStopped}
                  onChange={(option) => {
                    setFieldValue("medicalConclusion", option);
                    setDataInfor({
                      ...dataInfor,
                      medicalConclusion: option,
                      medicalConclusionCode: option?.code,
                      medicalConclusionName: option?.name,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="flex-1">
                <div className="d-flex">
                  <LabelRequired label="Hình thức xử trí" />
                </div>
                <AutocompleteObjectV2
                  options={listHTXT || []}
                  name="hinhThucXutri"
                  isDisabled={dataInfor?.isVisitStopped}
                  value={dataInfor?.medicalConclusionModality}
                  onChange={(option) => {
                    handleSelectOption(option, "medicalConclusionModality");
                    setDataInfor({
                      ...dataInfor,
                      hinhThucXutri: option,
                      medicalConclusionModalityCode: option?.code,
                      medicalConclusionModalityName: option?.name,
                    });
                  }}
                />
              </div>
              <div className="flex-1"></div>
            </div>
          </Col>

          <Col xs={8} className="ps-0">
            <div className="mb-2">
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Bệnh chính - ICD10"
                  className="min-w-150px"
                />
              </div>
              <Row className="spaces pe-0 align-items-center">
                <Col xs="3" className="pe-0 position-relative">
                  <TextField
                    name="icdXPrimary"
                    disabled={dataInfor?.isVisitStopped}
                    value={dataInfor?.icdXPrimary?.code || ""}
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => {
                        if (!dataInfor?.isVisitStopped) {
                          setICD10Field("icdXPrimary");
                          setSelectionModeICD10("radio");
                          setShouldOpenModalICD10(true);
                        }
                      }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </Col>
                <Col xs="9" className="ps-0">
                  <TextField
                    name="icdXPrimary"
                    value={
                      listICD10.find(
                        (item: any) =>
                          item.code === dataInfor?.icdXPrimary?.code
                      )?.name || ""
                    }
                    disabled={dataInfor?.isVisitStopped}
                  />
                </Col>
              </Row>
            </div>

            <div className="mb-2">
              <div className="spaces d-flex flex-row justify-content-between">
                <div className="d-flex ">
                  <LabelRequired
                    label="Bệnh kèm theo - ICD10"
                    className="min-w-150px"
                  />
                </div>
                {!dataInfor?.isVisitStopped && (
                  <Button
                    className="bg-white"
                    onClick={() => {
                      setICD10Field("comorbidity");
                      setSelectionModeICD10("checkbox");
                      setShouldOpenModalICD10(true);
                    }}
                    disabled={false}
                  >
                    <u className="color-17A2B8">Chọn bệnh kèm theo</u>
                  </Button>
                )}
              </div>
              <Row className="spaces pe-0 align-items-center">
                <TextField
                  name="comorbidity"
                  as="textarea"
                  rows={2}
                  value={dataInfor?.comorbidity || ""}
                  disabled={dataInfor?.isVisitStopped}
                />
              </Row>
            </div>
            <div className="mb-2">
              <div className="d-flex ">
                <LabelRequired
                  label="Bệnh chính - YHCT"
                  className="min-w-150px"
                />
              </div>
              <Row className="spaces pe-0 align-items-center">
                <Col xs="3" className="pe-0 position-relative">
                  <TextField
                    name="traditionalMedicinePrimary"
                    value={dataInfor?.traditionalMedicinePrimary?.code || ""}
                    disabled={dataInfor?.isVisitStopped}
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => {
                        if (!dataInfor?.isVisitStopped) {
                          setICD10Field("traditionalMedicinePrimary");
                          setSelectionModeICD10("radio");
                          setShouldOpenModalYHCT(true);
                        }
                      }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </Col>
                <Col xs="9" className="ps-0">
                  <TextField
                    name="traditionalMedicinePrimary"
                    value={
                      listICD10.find(
                        (item: any) =>
                          item.code ===
                          dataInfor?.traditionalMedicinePrimary?.code
                      )?.name || ""
                    }
                    disabled={dataInfor?.isVisitStopped}
                  />
                </Col>
              </Row>
            </div>

            <div>
              <div className="spaces d-flex flex-row justify-content-between">
                <div className="d-flex ">
                  <LabelRequired
                    label="Bệnh kèm theo - YHCT"
                    className="min-w-150px"
                  />
                </div>
                {!dataInfor?.isVisitStopped && (
                  <Button
                    className="bg-white"
                    onClick={() => {
                      setICD10Field("traditionalMedicineComorbidity");
                      setSelectionModeICD10("checkbox");
                      setShouldOpenModalYHCT(true);
                    }}
                    disabled={false}
                  >
                    <u className="color-17A2B8">Chọn bệnh kèm theo</u>
                  </Button>
                )}
              </div>
              <Row className="spaces pe-0 align-items-center">
                <TextField
                  name="traditionalMedicineComorbidity"
                  as="textarea"
                  rows={2}
                  value={dataInfor?.traditionalMedicineComorbidity || ""}
                  disabled={dataInfor?.isVisitStopped}
                />
              </Row>
            </div>
          </Col>
        </Row>

        <div className="d-flex">
          {values?.medicalConclusionModality?.code ===
            VARIABLE_STRING.CHUYEN_KHOA && (
            <div className="w-100">
              <LabelRequired label="Tên khoa" />
              {/* <AutocompleteObjectV2 options={[]} /> */}
            </div>
          )}
        </div>
        <Row>
          <Col xs={12} className="spaces mt-1">
            <div>
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Lời dặn của bác sĩ"
                  className="min-w-150px"
                />
              </div>
              <TextField
                as="textarea"
                rows={2}
                name="doctorComment"
                value={dataInfor?.doctorComment || ""}
                disabled={dataInfor?.isVisitStopped}
                onChange={(e: any) => {
                  setDataInfor({
                    ...dataInfor,
                    doctorComment: e.target.value,
                  });
                  setFieldValue("doctorComment", e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex gap-3 py-2 bg-white flex-start mt-10">
          {/* <Button className="btn-fill" onClick={handleSubmit}>
            Xử trí
          </Button> */}
          <WfButton
            tracker={WfConstTracker.CLINICAL}
            objectId={benhNhanInfo?.thongTinKhamBenh?.orderId}
            additionalFunc={handleSubmit}
          ></WfButton>
        </div>
      </div>

      <DynamicModal compArray={compArray} nameComp={nameComp} />

      {shouldOpenModalICD10 && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeSelect}
          selectionMode={selectionModeICD10}
        />
      )}
      {shouldOpenModalYHCT && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalYHCT(false)}
          setMauSelected={handleChangeSelect}
          selectionMode={selectionModeICD10}
        />
      )}
    </Form>
  );
};

const TabXuTriHOC = () => {
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } =
    useContext(PhanHeTiepDonContext);
  
  const [dataInfor, setDataInfor] = useState<any>();
  const [listHTXT, setListHTXT] = useState<any[]>([]);
  const [listKQ, setListKQ] = useState<any[]>([]);
  const [modalityDetails, setModalityDetails] = useState<any>({});
  const [listICD10, setListICD10] = useState<any>([]);
  
  useEffect(() => {
    handleGetDanhMuc();
  }, []);

  useEffect(() => {
    setModalityDetails({});
  }, [dataInfor?.hinhThucXutri]);

  const getDataXuTri = async () => {
    try {
      if (benhNhanInfo?.thongTinKhamBenh?.orderId) {
        let { data } = await getDataXuTriService(
          benhNhanInfo?.thongTinKhamBenh?.orderId
        );
        if (data?.code === CODE.SUCCESS) {
          setDataInfor({
            ...data?.data?.encounterDiagnosis,
            icdXPrimary: {
              code: data?.data?.encounterDiagnosis?.icdXPrimary,
            },
            traditionalMedicinePrimary: {
              code: data?.data?.encounterDiagnosis?.traditionalMedicinePrimary,
            },
            medicalConclusion: listKQ?.find((item: any) => {
              return (
                item?.code ===
                data?.data?.encounterDiagnosis?.medicalConclusionCode
              );
            }),
            medicalConclusionModality: listHTXT?.find((item: any) => 
                item?.code === data?.data?.encounterDiagnosis?.medicalConclusionModalityCode
            ),
            medicalConclusionDate: data?.data?.encounterDiagnosis?.medicalConclusionDate || formatDateDTO(new Date()),
          });
        }
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra");
    }
  };

  useEffect(() => {
    if (listKQ.length && listHTXT.length) getDataXuTri();

  }, [
    benhNhanInfo?.shouldUpdate,
    benhNhanInfo?.thongTinKhamBenh?.orderId,
    listKQ.length,
    listHTXT.length,
  ]);

  const handleSubmitXuTri = async (values: any) => {
    try {
      let encounterDiagnosis = {
        id: dataInfor?.id,
        ...dataInfor,
        finalDiagnosis: values?.finalDiagnosis || dataInfor?.finalDiagnosis,
        icdXCause: values?.icdXCause || dataInfor?.icdXCause,
        icdXPrimary:
          values?.encounterDiagnosis?.icdXPrimary ||
          dataInfor?.icdXPrimary?.code,
        comorbidity:
          values?.encounterDiagnosis?.comorbidity || dataInfor?.comorbidity,
        traditionalMedicinePrimary:
          values?.encounterDiagnosis?.traditionalMedicinePrimary ||
          dataInfor?.traditionalMedicinePrimary?.code,
        traditionalMedicineComorbidity:
          values?.encounterDiagnosis?.traditionalMedicineComorbidity ||
          dataInfor?.traditionalMedicineComorbidity,
        medicalConclusionDate:
          values?.medicalConclusionDate || dataInfor?.medicalConclusionDate,
        medicalConclusion:
          values?.medicalConclusion?.code || dataInfor?.medicalConclusion?.code,
        medicalConclusionModality:
          values?.medicalConclusionModality?.code ||
          dataInfor?.medicalConclusionModality?.code,
        doctorComment: values?.doctorComment || dataInfor?.doctorComment,
      };

      delete modalityDetails?.examinationRequestOption;
      delete modalityDetails?.clinicOption;
      let { data } = await updateXuTri({
        modalityDetails: modalityDetails,
        encounterDiagnosis: encounterDiagnosis,
        patientId: benhNhanInfo?.thongTinKhamBenh?.patient?.id,
        visitId: benhNhanInfo?.thongTinKhamBenh?.visit?.id,
        encounterId: benhNhanInfo?.thongTinKhamBenh?.encounter?.id,
        isClinicalTransfer:
          values?.medicalConclusionModality?.code ===
          MEDICAL_CONCLUSION_MODALITY.CHUYEN_PHONG.code,
      });
      if (CODE.SUCCESS === data.code) {
        toast.success("Thành Công");
        setBenhNhanInfo({
          ...benhNhanInfo,
          shouldUpdate: !benhNhanInfo?.shouldUpdate,
        });
        statusChangedEventFunc?.();
      } else {
        toast.error("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {}
  };

  const handleGetDanhMuc = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
      setListHTXT(res?.listHTXT || []);
      setListKQ(res?.listKQDT || []);
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <Formik
      initialValues={{
        medicalConclusionModality: null
      }}
      onSubmit={(values) => handleSubmitXuTri(values)}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <TabXuTri
          handleSubmit={handleSubmit}
          dataInfor={dataInfor}
          setDataInfor={setDataInfor}
          listHTXT={listHTXT}
          setListHTXT={setListHTXT}
          listKQ={listKQ}
          setModalityDetails={setModalityDetails}
          modalityDetails={modalityDetails}
          listICD10={listICD10}
        />
      )}
    </Formik>
  );
};

export default TabXuTriHOC;
