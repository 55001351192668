import DanhSachPhieu from '../../../../../component/DanhSachPhieu';
import { LIST_COLOR_TRANG_THAI_PHIEU } from '../../../../../utils/Constant';

type Props = {
	data: any[];
	onSelect: (data: any) => void;
	selectedIndex: number;
};

const DanhSachPhieuThuoc = ({ data, selectedIndex, onSelect }: Props) => {
	return (
		<div className="px-7 spaces pb-4">
			<DanhSachPhieu
				dsPhieu={data}
				handleSelectRowData={onSelect}
				title="price"
				listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
				selectedIndex={selectedIndex}
			/>
		</div>
	);
};

export default DanhSachPhieuThuoc;
