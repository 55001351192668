import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, Modal, Row } from 'react-bootstrap';
import { Row as ReactTableRowProps } from 'react-table';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AppContext } from '../../../appContext/AppContext';
import AutocompleteV2 from '../../../component/AutocompleteObjectV2';
import FormInPhieuDialog from '../../../component/button-in-phieu/components/PrintDialog';
import { ConfirmDialog } from '../../../component/ConfirmDialog';
import ContextMenu from '../../../component/ContextMenuV3';
import CustomIconButton from '../../../component/custom-icon-button/CustomIconButton';
import DatePickerCustom from '../../../component/date-picker/DatePickerCustom';
import { IconBack, IconButtonSave } from '../../../component/IconSvg';
import InputAutoComplete from '../../../component/input-autocomplete';
import LabelRequired from '../../../component/LabelRequired';
import NumberField from '../../../component/number-field';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';
import { TableCustom } from '../../../component/table/table-custom/TableCustom';
import TextField from '../../../component/TextField';
import { IDanhSachDanhMuc } from '../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import {
	CODE,
	CODE_SUCCESS,
	KEY_DS_DANH_MUC_TIEP_DON,
	ORDER_TYPE_ID,
	RESPONSE_MESSAGE,
} from '../../../utils/Constant';
import { formatMoney } from '../../../utils/FormatUtils';
import { getIndexedDBItem } from '../../../utils/IndexedDB';
import { localStorageItem } from '../../../utils/LocalStorage';
import InfoPatientRight from '../../components/InfoPatientRight';
import ModalICD10 from '../../components/modal-icd10/ModalICD10';
import ModalLuuMauThuocMoi from '../../components/modal-ke-thuoc/ModalLuuMauThuocMoi';
import ModalMauChiDinhThuoc from '../../components/modal-ke-thuoc/ModalMauChiDinhThuoc';
import { ThuocColumns } from '../../components/modal-tab-thuoc/ThuocColumns';
import SelectThuocButton from '../../components/select-thuoc-button';
import {
	CHI_PHI_THUOC_FIELDS,
	CHI_PHI_THUOC_TYPE,
	contextMenuKeThuocOptions,
	CONTEXT_MENU_KE_THUOC_KEY,
	CONTEXT_MENU_SUA_DOI_TUONG_KEYS,
	HDSD_THUOC_OPTIONS,
	LIEU_DUNG_THUOC_OPTIONS,
	MA_LOAI_DOI_TUONG,
	THOI_DIEM_DUNG_THUOC,
} from '../../constants/Thuoc';
import { LOAI_VAT_TU } from '../../constants/VatTu';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import { ThuocInfo } from '../../models/DSBenhNhanKhamBenhModels';
import {
	IInPhieuChiDinhThuocParams,
	IKeThuocRequest,
	IKeThuocThongTinChung,
	IKhoThuoc,
	IThoiDiemDung,
	IThuoc,
	IThuocModel,
} from '../../models/ThuocModels';
import {
	getDanhSachKhoThuoc,
	getDetailDonThuoc,
	printPhieuChiDinhThuoc,
	saveDonThuoc,
} from '../../services/ThuocService';
import { searchVatTu } from '../../services/VatTuService';
import ButtonCapToaChoVe from './components/ButtonCapToaChoVe';
import ButtonDonThuocCu from './components/header/ButtonDonThuocCu';
import NgayKeTuDong from './components/NgayKeTuDong';

type Props = {
	open: boolean;
	handleClose: () => void;
	itemEdit?: any;
	onSave?: () => void;
};

type SelectICD10 = {
	field: string;
	mode: 'radio' | 'checkbox';
	open: boolean;
};

const PageKeThuoc = ({ open, handleClose, itemEdit, onSave }: Props) => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { setIsLoading } = useContext(AppContext);

	const formikRef = useRef<FormikProps<IThuocModel>>(null);
	const rowSelectedRef = useRef<any>(null);
	const idDonThuocRef = useRef<string>('');
	const danhSachThuocRef = useRef<any[]>([]);

	const [danhSachKhoThuoc, setDanhSachKhoThuoc] = useState<IKhoThuoc[]>([]);
	const [thuocSelected, setThuocSelected] = useState<IThuoc>();
	const [tableData, setTableData] = useState<ThuocInfo[]>([]);
	const [listICD10, setListICD10] = useState<any>([]);
	const [selectICD10, setSelectICD10] = useState<SelectICD10>({
		field: '',
		mode: 'radio',
		open: false,
	});
	const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	}>(null);
	const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

	const [openLuuMauThuocMoiDialog, setOpenLuuMauThuocMoiDialog] = useState<boolean>(false);
	const [openDanhSachMauDialog, setOpenDanhSachMauDialog] = useState<boolean>(false);
	const [thuocDetail, setThuocDetail] = useState<any>();
	const [khoThuocSelected, setKhoThuocSelected] = useState<IKhoThuoc>();
	const [loaiDoiTuong, setLoaiDoiTuong] = useState<any[]>([]);
	const [openPrintDialog, setOpenPrintDialog] = useState<boolean>(false);

	const { patient, visit, encounterDiagnosis, encounter } = benhNhanInfo.thongTinKhamBenh;
	const userInfo = localStorageItem.get('access-token-decode-patient');
	const validationSchema = Yup.object().shape({});
	const initialThuocInfoValue = {
		idThuoc: '',
		maThuoc: '',
		tenThuoc: '',
		actIngName: '',
		soLuongKhaDung: 0,
		donViTinh: '',
		duongDung: '',
		soNgayDung: 0,
		quantityInMorning: 0,
		quantityInNoon: 0,
		quantityInAfternoon: 0,
		quantityInEvening: 0,
		tongSoThuoc: 0,
		lieuDung: '',
		huongDanSuDungThuoc: '',
		index: undefined,
	};
	const initialFormValues: IThuocModel = {
		ngayYLenh: new Date().toString(),
		ghiChu: '',
		khoThuoc: '',
		bacSiDieuTri: userInfo.name,
		loiDanBacSi: '',
		dotDung: '',
		ngayTaiKham: '',
		ngayKeTuDong: '',
		provisionalDiagnosis:
			benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.provisionalDiagnosis,
		comorbidity: benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.comorbidity,
		...initialThuocInfoValue,
	};
	const thongTinChiPhi = {
		tongChiPhi: visit.ttTong,
		bhyt: visit.ttBhyt,
		benhNhan: visit.ttNguoiBenh,
		tamUng: visit.ttTamUng,
		daThu: visit.ttDaThu,
		mienGiam: visit.ttDiscountInv,
		conDu: visit.ttConNo,
	};
	const tongTienDonThuoc = tableData.reduce((acc: number, row: ThuocInfo) => {
		return acc + (row.deleted ? 0 : row.thanhTien || 0);
	}, 0);
	const tongTienConDu = formatMoney(
		Math.abs(
			thongTinChiPhi.tamUng +
				thongTinChiPhi.daThu -
				(thongTinChiPhi.benhNhan + tongTienDonThuoc)
		)
	);

	useEffect(() => {
		getKhoThuoc();
		getDanhMuc();
	}, []);

	useEffect(() => {
		if (itemEdit?.id) {
			getDonThuoc(itemEdit.id);
		}
	}, [itemEdit]);

	useEffect(() => {
		if (thuocSelected?.id && formikRef.current) {
			formikRef.current.setFieldValue('donViTinh', thuocSelected.unitofMeasureName);
			formikRef.current.setFieldValue('duongDung', thuocSelected.roa);
			formikRef.current.setFieldValue('actIngName', thuocSelected.actIngName);
			formikRef.current.setFieldValue('idThuoc', thuocSelected.id);
			formikRef.current.setFieldValue('maThuoc', thuocSelected.code);
			formikRef.current.setFieldValue('soLuongKhaDung', thuocSelected.iventory);
		}
	}, [thuocSelected]);

	useEffect(() => {
		if (danhSachKhoThuoc.length && thuocDetail?.id) {
			setKhoThuocSelected(getDetailKhoThuocSelected(thuocDetail?.warehouseId));
		}
	}, [danhSachKhoThuoc.length, thuocDetail?.id]);

	useEffect(() => {
		if (khoThuocSelected?.id) {
			getDanhSachThuoc(khoThuocSelected.id.toString());
		}
	}, [khoThuocSelected?.id]);

	const getKhoThuoc = async () => {
		try {
			const data: IKhoThuoc[] = await getDanhSachKhoThuoc();
			setDanhSachKhoThuoc(data);
		} catch (error) {
			console.error(error);
		}
	};

	const getDanhMuc = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		setListICD10(danhMuc.listICD10);
		setLoaiDoiTuong(danhMuc.listDoiTuong);
	};

	const handleSubmit = async (values: IThuocModel, formHelpers: FormikHelpers<any>) => {
		if (!values.tenThuoc) {
			toast.warning('Chưa chọn thuốc kê đơn');
			return;
		}

		if ((values.tongSoThuoc || 0) > (values.soLuongKhaDung || 0)) {
			toast.warning('Số lượng thuốc kê đơn không lớn hơn số lượng thuốc khả dụng');
			return;
		}

		let listTemp = [...tableData];
		const rowData: any = {
			...values,
			id: values?.tableRowId,
			maThuoc: values.maThuoc,
			tenThuoc: values.tenThuoc,
			hoatChat: values.actIngName,
			donVi: thuocSelected?.unitofMeasureName || values.donViTinh,
			soLuong: values.tongSoThuoc || 0,
			duongDung: values.duongDung || thuocSelected?.roa,
			lieuDung: values.lieuDung,
			huongDanSuDungThuoc: values.huongDanSuDungThuoc,
			donGia: thuocSelected?.requestedPrice || 0,
			thanhTien:
				thuocSelected && values.tongSoThuoc
					? thuocSelected?.requestedPrice * values.tongSoThuoc
					: 0,
			doiTuong: benhNhanInfo?.thongTinKhamBenh?.visit?.visitObjectTypeName,
		};

		if (rowData.index !== null && rowData.index !== undefined) {
			listTemp[rowData.index] = {
				...listTemp[rowData.index],
				...rowData,
			};
		} else {
			listTemp = [...listTemp, rowData];
		}

		setTableData(listTemp.map((row, index) => ({ ...row, index })));
		formHelpers.resetForm({
			values: {
				...values,
				...initialThuocInfoValue,
			},
		});
	};

	const countTongSoLuongThuoc = (formValues: IThuocModel) => {
		if (!formValues.soNgayDung || isEmptyLieuDungThuoc(formValues)) {
			formikRef.current?.setFieldValue('tongSoThuoc', '');
			return;
		}

		const lieuDungThuocTrenNgay = getTongSoThuocTrenNgay(formValues);
		const tongSoThuoc = formValues.soNgayDung * lieuDungThuocTrenNgay;
		formikRef.current?.setFieldValue('tongSoThuoc', tongSoThuoc);
	};

	const getTongSoThuocTrenNgay = (values: IThuocModel) => {
		return (
			(Number(values.quantityInMorning) || 0) +
			(Number(values.quantityInNoon) || 0) +
			(Number(values.quantityInAfternoon) || 0) +
			(Number(values.quantityInEvening) || 0)
		);
	};

	const isEmptyLieuDungThuoc = (values: IThuocModel) => {
		return [
			values.quantityInMorning,
			values.quantityInNoon,
			values.quantityInAfternoon,
			values.quantityInEvening,
		].every(item => !Boolean(item));
	};

	const onChangeICD10 = (field: string, data: any) => {
		switch (field) {
			case 'provisionalDiagnosis':
				formikRef.current?.setFieldValue(field, data?.code);
				break;
			case 'comorbidity':
				const comorbidityName = data?.map((item: any) => item?.name).join('; ');
				formikRef.current?.setFieldValue(field, comorbidityName);
				break;
			default:
				break;
		}
	};

	const getChiPhiThuocValue = (field: CHI_PHI_THUOC_TYPE) => {
		const MAP_FIELD_VALUE = {
			[CHI_PHI_THUOC_FIELDS.TIEN_DON]: tongTienDonThuoc,
			[CHI_PHI_THUOC_FIELDS.TONG_CHI_PHI]: thongTinChiPhi.tongChiPhi + tongTienDonThuoc,
			[CHI_PHI_THUOC_FIELDS.BHYT]: thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.NGUON_KHAC]: 0,
			[CHI_PHI_THUOC_FIELDS.BENH_NHAN]:
				thongTinChiPhi.benhNhan + tongTienDonThuoc - thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.TAM_UNG]: thongTinChiPhi.tamUng,
			[CHI_PHI_THUOC_FIELDS.DA_THU]: thongTinChiPhi.daThu,
			[CHI_PHI_THUOC_FIELDS.MIEN_GIAM]: thongTinChiPhi.mienGiam,
			[CHI_PHI_THUOC_FIELDS.CON_DU]:
				thongTinChiPhi.tamUng +
				thongTinChiPhi.daThu -
				(thongTinChiPhi.benhNhan + tongTienDonThuoc),
		};

		return formatMoney(MAP_FIELD_VALUE[field] || 0);
	};

	const onSaveForm = async (callbackFn = onSave) => {
		try {
			const hasDrugData = tableData.filter(row => !row.deleted).length;
			if (!hasDrugData) {
				toast.warning('Hiện tại chưa có thuốc được kê cho đơn này');
				return;
			}

			setIsLoading(true);
			const formValues = formikRef.current?.values;

			if (!formValues) return;
			const loaiDoiTuongKham = loaiDoiTuong.find(
				record => record.code === visit.visitObjectTypeCode
			);
			const listThuoc: IKeThuocRequest[] = [];

			tableData.forEach((row: any) => {
				listThuoc.push({
					...row,
					visitId: row.visitId || visit.id,
					medicalItemId: String(row.idThuoc) || row.medicalItemId,
					medicalItemCode: row.maThuoc || row.medicalItemCode,
					name: row.tenThuoc,
					dosageForm: row.dosage || row.lieuDung,
					route: row.duongDung,
					usageInstructions: row.huongDanSuDungThuoc,
					unit: row.donVi,
					activeIngredients: row.hoatChat,
					numberOfDays: row.soNgayDung,
					quantityInMorning: row.quantityInMorning,
					quantityInNoon: row.quantityInNoon,
					quantityInAfternoon: row.quantityInAfternoon,
					quantityInEvening: row.quantityInEvening,
					totalQuantity: row.tongSoThuoc,
					unitPrice: row.donGia,
					intoMoney: row.thanhTien,
					visitObjectTypeId: loaiDoiTuongKham.id,
					visitObjectTypeName: loaiDoiTuongKham.name,
					orderTypeId: parseInt(ORDER_TYPE_ID.THUOC),
					patientId: patient.id,
					encounterId: benhNhanInfo.thongTinKhamBenh?.encounter?.id,
					orderId: benhNhanInfo.thongTinKhamBenh?.orderId,
				});
			});

			const donThuocRequest: IKeThuocThongTinChung = {
				...thuocDetail,
				doctorOrderDay: new Date(formValues.ngayYLenh),
				note: formValues.ghiChu,
				warehouseName: formValues.khoThuocName || khoThuocSelected?.name,
				warehouseId: formValues.khoThuocId || khoThuocSelected?.id,
				doctorAdvice: formValues.loiDanBacSi,
				usageTime: formValues.dotDung,
				reExaminationDate: formValues.ngayTaiKham
					? new Date(formValues.ngayTaiKham).toISOString()
					: null,
				automaticPrescriptionDate: formValues.ngayKeTuDong,
				designatedDate: new Date().toISOString(),
				patientId: patient.id,
				visitId: visit.id,
				treatmentDoctorUser: userInfo?.sub,
				treatmentDoctorName: userInfo.name,
				designatedPerson: userInfo.name,
				designatedPlace: userInfo.departments?.[0]?.name,
				lstMedicalItemOrder: listThuoc,
				encounterId: encounter?.id,
				orderTypeId: parseInt(ORDER_TYPE_ID.THUOC),
				comorbidity: formValues.comorbidity || encounterDiagnosis.comorbidity,
				provisionalDiagnosis:
					formValues.provisionalDiagnosis || encounterDiagnosis.provisionalDiagnosis,
				orderId: benhNhanInfo.thongTinKhamBenh?.orderId,
			};

			const { code, data } = await saveDonThuoc(donThuocRequest);
			if (code === CODE_SUCCESS) {
				toast.success(
					thuocDetail?.id ? RESPONSE_MESSAGE.UPDATE.SUCCESS : RESPONSE_MESSAGE.ADD.SUCCESS
				);
				if (data) {
					idDonThuocRef.current = data;
				}
				idDonThuocRef.current = data;
				callbackFn && callbackFn();
			} else {
				toast.error(data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const onClickContextMenu = (value: any) => {
		const actionCode = value.code;

		switch (actionCode) {
			case CONTEXT_MENU_KE_THUOC_KEY.XOA_THUOC:
				setOpenConfirmDelete(true);
				break;
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT_YC:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_VIEN_PHI:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_YEU_CAU:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_HAO_PHI_KHAC:
				onSuaDoiTuongThuoc(actionCode);
				break;
			default:
				break;
		}
		setContextMenu(null);
	};

	const handleContextMenu = (e: any, row: ReactTableRowProps) => {
		e.preventDefault();
		setContextMenu({ x: e.clientX, y: e.clientY });
		rowSelectedRef.current = {
			...row.original,
			index: row.index,
		};
	};

	const onRowDoubleClick = async (row: ReactTableRowProps<any>) => {
		const rowData = row.original;
		const detailSelectedThuoc = danhSachThuocRef.current.find(
			item => item.id === rowData.idThuoc
		);

		formikRef.current?.setValues({
			...thuocDetail,
			...rowData,
			index: row.index,
			tableRowId: rowData.id,
			maThuoc: rowData.medicalItemCode || rowData.maThuoc,
		});
		setThuocSelected({
			id: rowData.idThuoc || rowData.medicalItemId,
			code: rowData.maThuoc || rowData.medicalItemCode,
			name: rowData.tenThuoc,
			actIngName: rowData.hoatChat,
			requestedPrice: rowData.donGia,
			roa: rowData.duongDung,
			unitofMeasureName: rowData.donViTinh,
			atIngName: '',
			dosage: '',
			packaging: '',
			usageIns: 0,
			ROA: '',
			ROAName: '',
			iventory: detailSelectedThuoc?.iventory,
		});
	};

	const onDeleteThuoc = () => {
		setOpenConfirmDelete(false);
		let listTemp = [...tableData];
		const rowDelete = rowSelectedRef.current;

		if (rowDelete?.id) {
			listTemp[rowDelete.index].deleted = true;
		} else {
			listTemp = listTemp.filter(
				(row: ThuocInfo, index: number) => index !== rowDelete.index
			);
		}
		setTableData(listTemp);
	};

	const getDonThuoc = async (thuocId: string) => {
		try {
			setIsLoading(true);

			const { code, data } = await getDetailDonThuoc(thuocId);

			if (code === CODE.SUCCESS) {
				const formData: IThuocModel = {
					id: data.id,
					ghiChu: data.note,
					khoThuocId: data.warehouseId,
					bacSiDieuTri: data.treatmentDoctorName,
					loiDanBacSi: data.doctorAdvice,
					dotDung: data.usageTime,
					ngayTaiKham: formatLocalDatetimeToString(data.reExaminationDate),
					ngayKeTuDong: data.automaticPrescriptionDate,
					ngayYLenh: data.doctorOrderDay
						? new Date(`${data.doctorOrderDay}Z`).toString()
						: new Date().toString(),
					maThuoc: '',
					tenThuoc: '',
					khoThuoc: '',
				};
				formikRef.current &&
					formikRef.current.setValues({
						...initialFormValues,
						...formData,
					});
				setThuocDetail({
					...data,
					...formData,
				});
				idDonThuocRef.current = data.id;

				if ((data.lstMedicalItemOrderRes || []).length) {
					const listThuoc: (ThuocInfo & Partial<IThuocModel>)[] =
						mapDrugListResponseToTable(data.lstMedicalItemOrderRes);
					setTableData(listThuoc);
				}
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const formatLocalDatetimeToString = (value: Date | null) => {
		if (!value) return '';
		return moment(`${value}Z`).format('YYYY/MM/DD');
	};

	const onSuaDoiTuongThuoc = async (contextMenuValue: string) => {
		const detailThuoc = danhSachThuocRef.current.find(
			row => row.id === rowSelectedRef.current.idThuoc
		);
		const detailLoaiDoiTuong = getDetailLoaiDoiTuongByContextMenu(contextMenuValue);
		const rowUpdate = rowSelectedRef.current;

		const listTemp = tableData.map((row: any) => {
			if (row.index === rowUpdate.index) {
				const donGia = getDonGiaThuocByLoaiDoiTuong(detailThuoc, detailLoaiDoiTuong.code);
				return {
					...row,
					donGia: donGia,
					thanhTien: row.soLuong * donGia,
					visitObjectTypeId: detailLoaiDoiTuong.id,
					visitObjectTypeName: detailLoaiDoiTuong.name,
					doiTuong: detailLoaiDoiTuong.name,
				};
			}
			return row;
		});
		setTableData(listTemp);
	};

	const getDetailLoaiDoiTuong = (field: string, value: string) => {
		return loaiDoiTuong.find(row => row[field] === value);
	};

	const getDetailLoaiDoiTuongByContextMenu = (contextMenu: string) => {
		const MAP_ID_LOAI_DOI_TUONG_CONTEXT_MENU = {
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.BHYT
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT_YC]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.BHYT_YEU_CAU
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_VIEN_PHI]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.VIEN_PHI
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_YEU_CAU]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.YEU_CAU
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_HAO_PHI_KHAC]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.HAO_PHI_KHAC
			),
		};

		return MAP_ID_LOAI_DOI_TUONG_CONTEXT_MENU[contextMenu];
	};

	const getDonGiaThuocByLoaiDoiTuong = (thuocData: any, maLoaiDoiTuong: string) => {
		const MAP_DON_GIA_THUOC_LOAI_DOI_TUONG = {
			[MA_LOAI_DOI_TUONG.BHYT]: 'BHYTPrice',
			[MA_LOAI_DOI_TUONG.BHYT_YEU_CAU]: 'BHYTPrice',
			[MA_LOAI_DOI_TUONG.VIEN_PHI]: 'hospitalFeePrice',
			[MA_LOAI_DOI_TUONG.YEU_CAU]: 'requestedPrice',
		};
		return Number(
			thuocData[MAP_DON_GIA_THUOC_LOAI_DOI_TUONG[maLoaiDoiTuong] || 'requestedPrice']
		);
	};

	const mapDrugListResponseToTable = (data: any[]) => {
		const result: any[] = [];
		data.forEach((row: any, index: number) => {
			result.push({
				...row,
				id: row.id,
				tenThuoc: row.name,
				hoatChat: row.activeIngredients,
				donVi: row.unit,
				soLuong: row.totalQuantity,
				duongDung: row.route,
				lieuDung: row.dosageForm,
				donGia: row.unitPrice,
				thanhTien: row.intoMoney,
				doiTuong: row.visitObjectTypeName,
				huongDanSuDungThuoc: row.usageInstructions,
				quantityInMorning: row.quantityInMorning,
				quantityInNoon: row.quantityInNoon,
				quantityInAfternoon: row.quantityInAfternoon,
				quantityInEvening: row.quantityInEvening,
				donViTinh: row.unit,
				tongSoThuoc: row.totalQuantity,
				soNgayDung: row.numberOfDays,
				index,
				idThuoc: row.medicalItemId,
			});
		});
		return result;
	};

	const getDetailKhoThuocSelected = (id: string) => {
		if (!danhSachKhoThuoc.length || !id) return;
		return danhSachKhoThuoc.find(record => record.id === parseInt(id));
	};

	const onDonThuocCuSelect = (selectedData: any) => {
		if (selectedData.provisionalDiagnosis) {
			formikRef.current?.setFieldValue(
				'provisionalDiagnosis',
				selectedData.provisionalDiagnosis
			);
		}

		setKhoThuocSelected(getDetailKhoThuocSelected(selectedData.warehouseId));

		setTableData(mapDrugListResponseToTable(selectedData.medicalItems));
	};

	const getDanhSachThuoc = async (khoThuocId: string) => {
		try {
			const response = await searchVatTu({
				warehouseId: khoThuocId,
				materialGroup: LOAI_VAT_TU.THUOC,
				pageIndex: 1,
				pageSize: 100_000,
			});
			danhSachThuocRef.current = response;
		} catch (error) {
			console.error(error);
		}
	};

	const columnsActions = {
		Header: (props: any) => (
			<TableCustomHeader<ThuocInfo>
				tableProps={props}
				title={'Thao tác'}
				className="text-center text-light min-w-80px"
			/>
		),
		id: 'actions',
		Cell: ({ ...props }) => (
			<div className="d-flex justify-content-center">
				<CustomIconButton
					variant="edit"
					onClick={() => {
						const row = props.row;
						onRowDoubleClick(row);
					}}
				>
					<i className="bi bi-pencil-square text-navy"></i>
				</CustomIconButton>
				<CustomIconButton
					variant="delete"
					onClick={() => {
						rowSelectedRef.current = {
							...props.row.original,
							index: props.row.index,
						};
						setOpenConfirmDelete(true);
					}}
				>
					<i className="bi bi-trash3-fill text-danger"></i>
				</CustomIconButton>
			</div>
		),
	};

	return (
		<Modal
			className="modal-thuoc page-full"
			fullscreen
			dialogClassName="h-modal"
			size="xl"
			show={open}
			animation={false}
			centered
			backdropClassName="spaces top-50"
		>
			<Formik<IThuocModel>
				validationSchema={validationSchema}
				onSubmit={(values, formHelpers) => {
					handleSubmit(values, formHelpers);
				}}
				initialValues={initialFormValues}
				innerRef={formikRef}
			>
				{({ setFieldValue, values, getFieldProps, touched, errors, resetForm }) => (
					<Form className="spaces h-calc-vh-50">
						<input type="hidden" {...getFieldProps('index')} />
						<input type="hidden" {...getFieldProps('tableRowId')} />
						<input type="hidden" {...getFieldProps('maThuoc')} />

						<div className="thuoc-dialog h-100">
							<Modal.Header className="py-3 header-modal">
								<div className="d-flex gap-6">
									<div
										className="d-flex align-items-center gap-3"
										onClick={() => handleClose()}
									>
										<IconBack />
										<span className="fw-500">Quay lại</span>
									</div>
									<div className="d-flex spaces gap-10">
										<Dropdown className="dropdown-btn menu-icon">
											<Dropdown.Toggle className="btn-fill">
												<i className="bi bi-caret-down-fill"></i>
												Đơn thuốc mẫu
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-actions">
												<Dropdown.Item>
													<div className="ps-5 spaces line-height-30">
														Lưu vào mẫu mới
													</div>
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => setOpenDanhSachMauDialog(true)}
												>
													<div className="ps-5 spaces line-height-30">
														Danh sách các mẫu đã tạo
													</div>
												</Dropdown.Item>
												<Dropdown.Item className="bg-light spaces line-height-30">
													<b>Tổng hợp</b>
												</Dropdown.Item>
												<Dropdown.Item>
													<div className="ps-5 spaces line-height-30">
														1
													</div>
												</Dropdown.Item>
												<Dropdown.Item>
													<div className="ps-5 spaces line-height-30">
														2
													</div>
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>

										<ButtonDonThuocCu
											patientInfo={{
												visitId: visit.id,
											}}
											onSelect={onDonThuocCuSelect}
										/>

										<Button
											className="btn-fill"
											onClick={() =>
												toast.warning(
													'Không tìm thấy cấu hình phác đồ cho các mã bệnh : V16'
												)
											}
										>
											<i className="bi bi-caret-down-fill"></i>
											Sử dụng phác đồ
										</Button>
										<Button className="btn-fill spaces min-w-76">
											Kê vật tư
										</Button>
									</div>
								</div>
								{benhNhanInfo?.thongTinKhamBenh ? (
									<InfoPatientRight benhNhanInfo={benhNhanInfo} />
								) : (
									<div className="spaces h-65"></div>
								)}
							</Modal.Header>

							<Modal.Body className="py-6 px-0 spaces overflow-hidden spaces h-calc-vh-154">
								<div className="bg-white radius-2 spaces py-10 px-10">
									<div className="d-flex flex-wrap">
										<div className="spaces mb-4">
											<TextField
												label="Ngày y lệnh"
												name="ngayYLenh"
												labelClassName="ms-0 min-w-120-px"
												value={moment(
													values?.ngayYLenh
														? values.ngayYLenh
														: new Date()
												).format('DD/MM/YYYY - HH:mm')}
												disabled
											/>
										</div>
										<div className="spaces ml-10 position-relative">
											<TextField
												label="Chẩn đoán"
												className="spaces w-100"
												inputClassName="w-100"
												name="provisionalDiagnosis"
												labelClassName="ms-0 min-w-85px"
											/>
											<div className="spaces width-4">
												<h6
													className="position-absolute muc-huong cursor-pointer"
													onClick={() => {
														if (
															benhNhanInfo?.thongTinKhamBenh
																.isExamined
														) {
															setSelectICD10({
																field: 'provisionalDiagnosis',
																mode: 'radio',
																open: true,
															});
														}
													}}
												>
													<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
												</h6>
											</div>
										</div>
										<div className="flex-grow-1">
											<TextField
												inputClassName="w-100"
												name="provisionalDiagnosisLabel"
												value={
													Boolean(listICD10.length)
														? listICD10.find(
																(item: any) =>
																	item.code ===
																	values.provisionalDiagnosis
														  )?.name
														: ''
												}
												labelClassName="ms-0 min-w-95px"
											/>
										</div>
									</div>
									<Row className="spaces mt-4">
										<Col xs={6} className="d-flex">
											<div className="d-flex">
												<LabelRequired
													label="Bệnh kèm theo"
													className="d-inline-block spaces min-w-120-px text-truncate"
												/>
											</div>
											<div className="spaces position-relative w-100">
												<TextField
													className="w-100 d-flex flex-column align-items-start"
													inputClassName="flex-auto spaces h-25 radius-3px pre-line"
													name="comorbidity"
													labelClassName="ms-0 min-w-145px"
												/>
												<div className="spaces width-4">
													<h6
														className="position-absolute muc-huong cursor-pointer"
														onClick={() => {
															if (
																benhNhanInfo?.thongTinKhamBenh
																	.isExamined
															) {
																setSelectICD10({
																	field: 'comorbidity',
																	mode: 'checkbox',
																	open: true,
																});
															}
														}}
													>
														<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
													</h6>
												</div>
											</div>
										</Col>
										<Col xs={6}>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="Ghi chú"
												name="ghiChu"
												labelClassName="ms-0 min-w-85px"
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4">
										<Col xs={3} className="d-flex">
											<LabelRequired
												label="Kho Thuốc"
												className="min-w-120-px"
											/>
											<AutocompleteV2
												options={danhSachKhoThuoc}
												name="khoThuoc"
												value={
													getDetailKhoThuocSelected(
														values.khoThuocId || ''
													) || khoThuocSelected
												}
												className="autocomplete-custom-tiep-nhan radius spaces h-26"
												onChange={selectedOption => {
													setKhoThuocSelected(selectedOption);
													setFieldValue('khoThuoc', selectedOption?.code);
													setFieldValue('khoThuocId', selectedOption?.id);
													setFieldValue(
														'khoThuocName',
														selectedOption?.name
													);
												}}
												isDisabled={Boolean(tableData.length)}
											/>
										</Col>
										<Col xs={3}>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="BS điều trị"
												name="bacSiDieuTri"
												labelClassName="ms-0 min-w-95px"
												disabled
											/>
										</Col>
										<Col xs={6}>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="Lời dặn BS"
												name="loiDanBacSi"
												labelClassName="ms-0 spaces min-w-85"
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4">
										<Col xs={3}>
											<NumberField
												label="Đợt dùng"
												name="dotDung"
												labelClassName="ms-0 min-w-120-px"
											/>
										</Col>
										<Col xs={3}>
											<DatePickerCustom
												label="Ngày tái khám"
												name="ngayTaiKham"
												labelClassName="ms-0 spaces min-w-95"
												minDate={new Date()}
												value={values.ngayTaiKham || null}
												setDateValue={date => {
													setFieldValue('ngayTaiKham', date);
												}}
												touched={touched.ngayTaiKham}
												errors={errors.ngayTaiKham}
											/>
										</Col>
										<Col xs={3}>
											<NgayKeTuDong name='ngayKeTuDong' />
										</Col>
										<Col xs={1} />
									</Row>
								</div>

								<div className="bg-white radius-2 spaces py-10 px-10 mt-6">
									<Row className="spaces mt-4">
										<Col xs={9} className="position-relative">
											<SelectThuocButton
												value={values.maThuoc}
												setThuocSelected={setThuocSelected}
												maKhoThuoc={
													values.khoThuocId ||
													khoThuocSelected?.id.toString() ||
													''
												}
												label="Tên thuốc"
												labelClassName="min-w-120-px"
											/>
										</Col>
										<Col>
											<NumberField
												inputClassName="w-100"
												label="SL khả dụng"
												name="soLuongKhaDung"
												labelClassName="ms-0 spaces min-w-95"
												disabled
											/>
										</Col>
										<Col>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="Đơn vị"
												name="donViTinh"
												labelClassName="ms-0 min-w-56 spaces"
												disabled
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4">
										<Col xs={9} className="d-flex">
											<div className="w-20">
												<NumberField
													label="Số ngày"
													name="soNgayDung"
													labelClassName="ms-0 min-w-120-px"
													onBlur={() => {
														countTongSoLuongThuoc(values);
													}}
												/>
											</div>
											{THOI_DIEM_DUNG_THUOC.map((option: IThoiDiemDung) => (
												<div
													className="spaces width-15 ps-5"
													key={option.name}
												>
													<NumberField
														label={option.label}
														name={option.name}
														labelClassName="ms-0 min-w-55px"
														onBlur={() => {
															countTongSoLuongThuoc(values);
														}}
													/>
												</div>
											))}
											<div className="w-20 ps-5">
												<NumberField
													label="Số lượng"
													name="tongSoThuoc"
													labelClassName="ms-0 min-w-70px"
												/>
											</div>
										</Col>
										<Col xs={3}>
											<TextField
												inputClassName="w-100"
												label="Đường dùng"
												name="duongDung"
												labelClassName="ms-0 min-w-95px"
												disabled
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4 d-flex flex-nowrap">
										<Col xs={5}>
											<InputAutoComplete
												label="Liều dùng"
												name="lieuDung"
												options={LIEU_DUNG_THUOC_OPTIONS}
												placeholder="Liều dùng/lần , Số lần/ngày"
												className="spaces w-100"
												inputClassName="w-100"
												labelClassName="ms-0 min-w-120-px"
											/>
										</Col>
										<Col xs={4} className="spaces flex-auto">
											<InputAutoComplete
												className="spaces w-100"
												inputClassName="w-100"
												label="Hướng dẫn sử dụng"
												name="huongDanSuDungThuoc"
												placeholder="Khoảng cách dùng, thời điểm dùng, lưu ý"
												labelClassName="ms-0 min-w-140 spaces"
												options={HDSD_THUOC_OPTIONS}
											/>
										</Col>
										<Col xs={3} className="d-flex justify-content-end">
											<div className="d-flex gap-4">
												<Button className="btn-fill" type="submit">
													<i className="bi bi-check-lg"></i> Xác nhận
												</Button>
												<Button
													className="btn-outline"
													onClick={() => {
														resetForm({
															values: {
																...values,
																...initialThuocInfoValue,
															},
														});
													}}
												>
													<i className="bi bi-x-lg"></i> Hủy bỏ
												</Button>
											</div>
										</Col>
									</Row>
								</div>

								<div className="spaces h-calc-vh-442 bg-white spaces py-10 px-0 radius-2 mt-6 table-ke-thuoc">
									<TableCustom<ThuocInfo>
										data={
											tableData.length
												? tableData.filter(row => !row.deleted)
												: []
										}
										columns={ThuocColumns}
										maxHeight={300}
										handleContextMenu={handleContextMenu}
										handleDoubleClick={onRowDoubleClick}
										columnsActions={columnsActions}
									/>
								</div>
							</Modal.Body>

							<Modal.Footer className="d-flex justify-content-between position-relative tien-don spaces pl-180 bottom-10 pt-4">
								<div className="border-right h-100 position-absolute left-0 spaces W-175 bottom-0 p-0 m-0 text-center d-flex align-items-center justify-content-center flex-column">
									<div className="fw-bold mt-2 text-danger">Tiền đơn</div>
									<div className="text-danger fw-bold text-center">
										{getChiPhiThuocValue('TIEN_DON')}
									</div>
								</div>
								<div className="d-flex">
									<div>
										<div className="spaces d-flex width-50 align-items-center mb-10">
											<LabelRequired
												label="Tổng chi phí"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-danger me-5 fw-500">
												{getChiPhiThuocValue('TONG_CHI_PHI')}
											</span>
										</div>
										<div className="spaces d-flex width-50 align-items-center">
											<LabelRequired
												label="Tạm ứng"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-pri me-5 fw-500">
												{getChiPhiThuocValue('TAM_UNG')}
											</span>
										</div>
									</div>
									<div>
										<div className="spaces d-flex width-50 align-items-center mb-10">
											<LabelRequired
												label="BHYT"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-pri me-5 fw-500">
												{getChiPhiThuocValue('BHYT')}
											</span>
										</div>
										<div className="spaces d-flex width-50 align-items-center">
											<LabelRequired
												label="Đã thu"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-danger me-5 fw-500">
												{getChiPhiThuocValue('DA_THU')}
											</span>
										</div>
									</div>
									<div>
										<div className="spaces d-flex width-50 align-items-center mb-10">
											<LabelRequired
												label="Nguồn khác"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-pri me-5 fw-500">
												{getChiPhiThuocValue('NGUON_KHAC')}
											</span>
										</div>
										<div className="spaces d-flex width-50 align-items-center">
											<LabelRequired
												label="Miễn giảm"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-pri me-5 fw-500">
												{getChiPhiThuocValue('MIEN_GIAM')}
											</span>
										</div>
									</div>
									<div>
										<div className="spaces d-flex width-50 align-items-center mb-10">
											<LabelRequired
												label="Bệnh nhân"
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-pri me-5 fw-500">
												{getChiPhiThuocValue('BENH_NHAN')}
											</span>
										</div>
										<div className="spaces d-flex width-50 align-items-center">
											<LabelRequired
												label={
													parseInt(
														getChiPhiThuocValue('CON_DU').toString()
													) < 0
														? 'Còn nợ'
														: 'Còn dư'
												}
												className="spaces ms-0 min-w-150"
											/>
											<span className="text-pri me-5 fw-500">
												{tongTienConDu}
											</span>
										</div>
									</div>
								</div>
								<div className="d-flex gap-3">
									<ButtonCapToaChoVe
										patientInfo={{
											patientId: patient.id,
											encounterId: encounter.id,
											orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
											visitId: visit.id,
										}}
										drugInfo={{
											doctorComment: values.loiDanBacSi,
										}}
										onSaveForm={onSaveForm}
										setIsLoading={setIsLoading}
										onClose={handleClose}
									/>
									<Button
										className="btn-fill"
										onClick={() => {
											onSaveForm(() => {
												setOpenPrintDialog(true);
											});
										}}
									>
										<i className="bi bi-printer" />
										Lưu và in
									</Button>
									<Button
										className="btn-fill"
										onClick={() => {
											onSaveForm();
										}}
									>
										<IconButtonSave /> Lưu lại
									</Button>
								</div>
							</Modal.Footer>
						</div>

						{selectICD10.open && (
							<ModalICD10
								data={listICD10}
								handleClose={() =>
									setSelectICD10({
										field: '',
										mode: 'radio',
										open: false,
									})
								}
								setMauSelected={data => onChangeICD10(selectICD10.field, data)}
								selectionMode={selectICD10.mode}
							/>
						)}

						{openLuuMauThuocMoiDialog && (
							<ModalLuuMauThuocMoi
								open={openLuuMauThuocMoiDialog}
								handleClose={() => {
									setOpenLuuMauThuocMoiDialog(false);
								}}
							/>
						)}

						{openDanhSachMauDialog && (
							<ModalMauChiDinhThuoc
								open={openDanhSachMauDialog}
								handleClose={() => {
									setOpenDanhSachMauDialog(false);
								}}
							/>
						)}

						{contextMenu && (
							<ContextMenu
								handleClickOptionContextMenu={onClickContextMenu}
								handleCloseMenu={() => setContextMenu(null)}
								data={contextMenuKeThuocOptions}
								target={contextMenu}
							/>
						)}

						{openConfirmDelete && (
							<ConfirmDialog
								className="z-index-1060"
								show={openConfirmDelete}
								title="Thông báo"
								message="Bạn có chắc chắn muốn xóa thuốc này không ?"
								yes="Xác nhận"
								close="Đóng"
								onCloseClick={() => {
									setOpenConfirmDelete(false);
									rowSelectedRef.current = null;
								}}
								onYesClick={onDeleteThuoc}
							/>
						)}

						{openPrintDialog && (
							<FormInPhieuDialog<IInPhieuChiDinhThuocParams>
								show={openPrintDialog}
								onHide={() => {
									setOpenPrintDialog(false);
									onSave?.();
								}}
								fetchExport={printPhieuChiDinhThuoc}
								params={{
									fileType: 'pdf',
									patientId: patient?.id,
									medicalItemGroupId: idDonThuocRef.current,
									visitId: visit?.id,
									type: parseInt(ORDER_TYPE_ID.THUOC),
								}}
							/>
						)}
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default PageKeThuoc;
