import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, FormCheck, Row } from "react-bootstrap";
import CustomDropdownButton from "../../../component/CustomDropdownButton";
import TextField from "../../../component/TextField";
import { trangThaiBenhNhan } from "../../constants/BenhNhanConst";
import {
  constTypeBenhKemTheo,
  initialValuesObs,
} from "../../constants/KhamBenh";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import {
  KetQuaDichVu,
  MauKhamBenh
} from "../../models/ThongTinKhamBenhModel";
import { DataDanhSachMauKhamBenh } from "../FakeData";
import ModalBenhKemTheo from "../ModalBenhKemTheo";
import ModalICD10 from "../modal-icd10/ModalICD10";
import ModalDanhSachLanKham from "../modal-thong-tin-kham-benh/ModalDanhSachLanKham";
import ModalDanhSachMauKhamBenhHoiBenh from "../modal-thong-tin-kham-benh/ModalDanhSachMauKhamBenhHoiBenh";
import ModalMauKhamBenhHoiBenh from "../modal-thong-tin-kham-benh/ModalMauKhamBenhHoiBenh";
import ModalTrieuChung from "../modal-thong-tin-kham-benh/ModalTrieuChung";
import ModalTuyChonBaoCao from "../modal-thong-tin-kham-benh/ModalTuyChonBaoCao";
import SinhHieu from "./SinhHieu";
import ModalChonKetQuaDichVu from "./modal-chon-ket-qua-dich-vu/ModalChonKetQuaDichVu";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import { KEY_DS_DANH_MUC_TIEP_DON } from "../../../utils/Constant";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";

interface Props {
  hideFooter?: true;
}

export const PhieuKhamBenh: FC<Props> = ({ hideFooter }) => {
  const [openModalChonKetQuaDichVu, setOpenModalChonKetQuaDichVu] =
    useState<boolean>(false);
  const [ketQuaCLS, setKetQuaCLS] = useState<KetQuaDichVu[]>([]);
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
  const [openModalTuyChonBaoCao, setOpenModalTuyChonBaoCao] =
    useState<boolean>(false);
  const [mauSelected, setMauSelected] = useState<MauKhamBenh | null>(null);
  const [
    openModalDanhSachMauKhamBenhHoiBenh,
    setOpenModalDanhSachMauKhamBenhHoiBenh,
  ] = useState<boolean>(false);
  const [openModalDanhSachLanKham, setOpenModalDanhSachLanKham] =
    useState<boolean>(false);
  const [openModalMauKhamBenh, setOpenModalMauKhamBenh] =
    useState<boolean>(false);
  const [dataDanhSachMau, setDataDanhSachMau] = useState<MauKhamBenh[]>(
    DataDanhSachMauKhamBenh
  );
  const [openModal, setOpenModal] = useState<any>({});
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [icd10Field, setICD10Field] = useState<string>("");
  const [selectionModeICD10, setSelectionModeICD10] = useState<"checkbox" | "radio">();
  const [listICD10, setListICD10] = useState<any>([]);

  let { values, setFieldValue, resetForm, setValues, errors, touched } = useFormikContext<any>();
  let encounterDiagnosisValues = values?.encounterDiagnosis;
  let encounterDiagnosisErrors = errors?.encounterDiagnosis as FormikErrors<any>;
  let encounterDiagnosisTouched = touched?.encounterDiagnosis as FormikTouched<any>;
  let obsValues = values?.obs;
  let obsErrors = errors?.obs as FormikErrors<any>;
  let obsTouched = touched?.obs as FormikTouched<any>;

  const handleChangeEncounterDiagnosis = (name: string, value: any) => {
    setFieldValue("encounterDiagnosis", {
      ...encounterDiagnosisValues,
      [name]: value,
    });
  };

  const handleChangeObs = (name: string, value: any) => {
    setFieldValue("obs", {
      ...obsValues,
      [name]: value,
    });
  };

  const handleChangeICD10 = (data: any) => {
    switch (icd10Field) {
      case "provisionalDiagnosis":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        break;
      case "comorbidity":
        let comorbidity = data?.map((item: any) => item?.name).join("; ");
        handleChangeEncounterDiagnosis(`${icd10Field}`, comorbidity || "");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    mauSelected?.id && setValues({ ...mauSelected });
  }, [mauSelected]);

  useEffect(() => {
		setFieldValue('encounterDiagnosis', {
      ...encounterDiagnosisValues,
			paraclinicalSummary: ketQuaCLS
				.map(
					item =>
						`${item.tenDichVu} ${item.ketQua !== null ? '(' + item.ketQua + ')' : ''}`
				)
				.join(', '),
		});
  }, [ketQuaCLS]);

  useEffect(() => {
    let encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis;
    let obs = benhNhanInfo?.thongTinKhamBenh?.obs || initialValuesObs;
    if (encounterDiagnosis || obs) {
      setValues({
        encounterDiagnosis,
        ...(obs && { obs }),
      });
    } else {
      resetForm();
    }
  }, [benhNhanInfo?.thongTinKhamBenh]);

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleGetListICD10();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setFieldValue("encounterDiagnosis", {
      ...encounterDiagnosisValues,
      provisionalDiagnosis: value
    });
  };

  return (
    <div className="modelKhamBenh spaces h-calc-vh-285">
      <div className={`thongTinKhamBenh ${hideFooter ? "ps-4" : "ps-0"}`}>
        <Row>
          <Row className="spaces ml-8">
            <h4 className="text-title fw-bold fs-5 mb-0">
              I. Lý do vào viện
              <span className="text-danger"> (*)</span></h4>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              as="textarea"
              rows={3}
              name="reasonForEncounter"
              value={encounterDiagnosisValues?.reasonForEncounter || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined }
              touched={encounterDiagnosisTouched?.reasonForEncounter}
              errors={encounterDiagnosisErrors?.reasonForEncounter}
            />
          </Row>
          <Row className="spaces ml-8">
            <h4 className="text-title fw-bold fs-5 mb-0">II. Hỏi bệnh</h4>
          </Row>
          <Row className="spaces ml-10 mt-5">
            <h4 className="fw-bold fs-6 mb-0">1. Quá trình bệnh lý</h4>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              name="pathologicalProcess"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.pathologicalProcess || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>
          <Row className="spaces ml-10 mt-5">
            <h4 className="fw-bold fs-6 mb-0">2. Tiền sử bệnh</h4>
          </Row>
          <Row className="spaces ml-10 mt-10">
            <p className="fs-6 mb-0">
              &#8226; Bản thân (Phát triển thể lực từ nhỏ đến lớn, những bệnh đã
              mắc, phương pháp ĐT, tiêm phòng, ăn uống, sinh hóa, vv...)
              <span className="text-danger"> (*)</span>
            </p>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              name="medicalHistoryPast"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.medicalHistoryPast || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
              touched={encounterDiagnosisTouched?.medicalHistoryPast}
              errors={encounterDiagnosisErrors?.medicalHistoryPast}
            />
          </Row>
          <Row className="spaces ml-10 mt-10">
            <p className="fs-6 mb-0">
              &#8226; Gia đình (Những người trong gia đình: Bệnh đã mắc, đời
              sống, tinh thần, vật chất, vv...)
            </p>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              name="medicalHistoryFamily"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.medicalHistoryFamily || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>

          <Row className="spaces ml-8">
            <h4 className="text-title fw-bold fs-5 mb-0">III. Khám bệnh</h4>
          </Row>
          <Row className="spaces ml-10 mt-5">
            <h4 className="fw-bold fs-6 mb-0">
              1. Toàn thân (Ý thức, da niêm mạc, hệ thống hạch, tuyến giáp, vị
              trí, kích thước, số lượng, di động, vv...)
            </h4>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              as="textarea"
              rows={2}
              name="physicalExamGeneral"
              value={encounterDiagnosisValues?.physicalExamGeneral || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>
          <Row className="mt-2 m-8 spaces pe-0 align-items-center">
            <SinhHieu
              sinhHieu={obsValues}
              setSinhHieu={handleChangeObs}
              error={obsErrors}
              touched={obsTouched}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>
          <Row className="spaces ml-10 mt-5">
            <h4 className="fw-bold fs-6 mb-0">2. Các bộ phận</h4>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              name="physicalExamOfBody"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.physicalExamOfBody || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>

          <div className="spaces ml-10 mt-5 mb-n2 d-flex flex-row justify-content-between ps-6">
            <h4 className="fw-bold fs-6">3. Tóm tắt kết quả cận lâm sàng</h4>

            <Button
              className="bg-white"
              onClick={() => {
                benhNhanInfo?.thongTinKhamBenh?.isExamined && setOpenModalChonKetQuaDichVu(true);
              }}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            >
              <u className="fw-bold text-pri fs-13px">Chọn dịch vụ</u>
            </Button>
          </div>
          <Row className="m-8 spaces pe-0 align-items-center position-relative">
            <TextField
              className="flex-auto h-50"
              name="paraclinicalSummary"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.paraclinicalSummary || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>
          <Row className="spaces ml-10 mt-5">
            <h4 className="fw-bold fs-6 mb-0">4. Chẩn đoán ban đầu</h4>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <Col xs="3" className="pe-0 position-relative">
              <TextField
                name="provisionalDiagnosis"
                value={encounterDiagnosisValues?.provisionalDiagnosis || ""}
                disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
                onChange={(e: any) =>
                  handleChange(e)
                }
              />
              <div className="spaces width-4">
                <h6
                  className="position-absolute muc-huong cursor-pointer"
                  onClick={() => {
                    if (benhNhanInfo?.thongTinKhamBenh?.isExamined) {
                      setICD10Field("provisionalDiagnosis");
                      setSelectionModeICD10("radio");
                      setShouldOpenModalICD10(true);
                    }
                  }}
                >
                  <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                </h6>
              </div>
            </Col>
            <Col xs="9" className="ps-0">
              <TextField
                name="provisionalDiagnosis"
                value={
                  listICD10.find(
                    (item: any) =>
                      item.code ===
                      encounterDiagnosisValues?.provisionalDiagnosis
                  )?.name || encounterDiagnosisValues?.provisionalDiagnosisName || ""
                }
                disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
              />
            </Col>
          </Row>
          <div className="spaces ml-10 mt-5 mb-n2 d-flex flex-row justify-content-between ps-6">
            <h4 className="fw-bold fs-6 mb-0">5. Bệnh kèm theo (nếu có)</h4>
            <Button
              className="bg-white"
              onClick={() => {
                setICD10Field("comorbidity");
                setSelectionModeICD10("checkbox");
                setShouldOpenModalICD10(true);
              }}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            >
              <u className="fw-bold text-pri fs-13px">Chọn bệnh kèm theo</u>
            </Button>
          </div>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              name="comorbidity"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.comorbidity || ""}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>
          <Row className="spaces ml-10 mt-5">
            <h4 className="fw-bold fs-6 mb-0">6. Thuốc đang dùng</h4>
          </Row>
          <Row className="m-8 spaces pe-0 align-items-center">
            <TextField
              name="currentMedications"
              as="textarea"
              rows={2}
              value={encounterDiagnosisValues?.currentMedications || ""}
              onChange={(e: any) =>
                handleChangeEncounterDiagnosis(e.target.name, e.target.value)
              }
              readOnlyText={benhNhanInfo?.isView}
              disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
            />
          </Row>
        </Row>
      </div>
      {!hideFooter && (
        <div className="flex justify-content-between spaces ml-7">
          <div className="flex justify-content-between gap-4 pt-3 pb-2 btn-luu">
            {!benhNhanInfo?.thongTinKhamBenh?.isConclusion && (
              <Button
                className="btn-fill spaces min-w-80px h-29"
                type="submit"
                disabled={
                  benhNhanInfo?.trangThai ===
                  trangThaiBenhNhan.ketThucKham.code ||
                  !benhNhanInfo?.thongTinKhamBenh?.isExamined
                }
              // onClick={() => handleSubmit()}
              >
                <span>Lưu</span>
              </Button>
            )}
            <Button
              className="btn-fill spaces min-w-100px h-29"
              onClick={() => setOpenModalTuyChonBaoCao(true)}
            >
              Tờ điều trị
            </Button>
            <CustomDropdownButton
              drop="up"
              listMenuItems={dataDanhSachMau.map((item) => ({
                ...item,
                title: item.tenMau,
                desc: "Chẩn đoán",
                onClick: () => setValues(item),
              }))}
              label="Mẫu khám bệnh"
              primaryItemLabel="Danh sách mẫu khám"
              className="spaces W-200"
              menuItemsClassName="spaces W-200"
              onClick={() => setOpenModalDanhSachMauKhamBenhHoiBenh(true)}
            />
            <CustomDropdownButton
              drop="up"
              listMenuItems={Array.from({ length: 3 }, (_, i) => ({
                title: "05/07/2024",
                desc: "Q3 - Khám tai-mũi-họng định kì",
              }))}
              label="Đợt khám gần nhất"
              primaryItemLabel="Danh sách lần khám cũ"
              className="spaces W-200"
              menuItemsClassName="spaces W-200"
              onClick={() => setOpenModalDanhSachLanKham(true)}
            />
          </div>
          <FormCheck label="Bệnh nhân cấp sổ khám mới" className="ms-5" />
        </div>
      )}
      {openModalMauKhamBenh && (
        <ModalMauKhamBenhHoiBenh
          mauSelected={mauSelected as MauKhamBenh}
          setMauSelected={setMauSelected}
          dataDanhSachMau={dataDanhSachMau}
          setDataDanhSachMau={setDataDanhSachMau}
          handleClose={() => setOpenModalMauKhamBenh(false)}
        />
      )}
      {openModalTuyChonBaoCao && (
        <ModalTuyChonBaoCao
          handleClose={() => setOpenModalTuyChonBaoCao(false)}
        />
      )}
      {openModalDanhSachMauKhamBenhHoiBenh && (
        <ModalDanhSachMauKhamBenhHoiBenh
          setMauSelected={setMauSelected}
          handleClose={() => setOpenModalDanhSachMauKhamBenhHoiBenh(false)}
        />
      )}
      {openModalDanhSachLanKham && (
        <ModalDanhSachLanKham
          setMauSelected={setMauSelected}
          handleClose={() => setOpenModalDanhSachLanKham(false)}
        />
      )}
      {openModalChonKetQuaDichVu && (
        <ModalChonKetQuaDichVu
          open={openModalChonKetQuaDichVu}
          handleClose={() => setOpenModalChonKetQuaDichVu(false)}
          onSave={(selectedData: KetQuaDichVu[]) => setKetQuaCLS(selectedData)}
          visitId={benhNhanInfo.thongTinKhamBenh.visit.id}
        />
      )}
      {openModal.benhKemTheo && (
        <ModalBenhKemTheo
          type={constTypeBenhKemTheo.ICD10.code}
          setListBenhKemTheo={(item: string) =>
            setFieldValue(constTypeBenhKemTheo.ICD10.code, item)
          }
          handleClose={() => setOpenModal({})}
        />
      )}
      {openModal.trieuChung && (
        <ModalTrieuChung
          setListTrieuChung={(item: string) =>
            setFieldValue("trieuChung", item)
          }
          handleClose={() => setOpenModal({})}
        />
      )}

      {shouldOpenModalICD10 && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeICD10}
          selectionMode={selectionModeICD10}
        />
      )}
    </div>
  );
};

export default PhieuKhamBenh;
