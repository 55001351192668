import { OverlayTrigger, Tooltip } from "react-bootstrap"
import "./CustomIconButton.scss"

type Props = {
  children: JSX.Element
  onClick: (e: any) => void
  variant: "delete" | "edit"
  title?: string
}

const renderTooltip = (title: string) => (
  title ? (<Tooltip className="button-tooltip">
    {title}
  </Tooltip >) : <></>
);

const CustomIconButton = ({children, onClick, variant, title}: Props) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 150, hide: 300 }}
      overlay={renderTooltip(title || "")}
    >
    <div className={`custom-icon-button spaces mx-2 ${variant}`} onClick={onClick}>
      {children}
    </div>
    </OverlayTrigger>
  )
}

export default CustomIconButton
