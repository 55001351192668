import { AxiosResponse } from 'axios';
import { ReactNode, useState } from 'react';
import { Button } from 'react-bootstrap';
import FormInPhieuDialog from './components/PrintDialog';

type ButtonInProps<T> = {
	label?: ReactNode;
	fetchExport: (params: T) => Promise<AxiosResponse<Blob, any>>;
	params: T;
	className?: string;
};

const ButtonIn = <T extends object>({
	label = 'In phiếu',
	fetchExport,
	params,
	className,
}: ButtonInProps<T>) => {
	const [openPrintDialog, setOpenPrintDialog] = useState(false);

	return (
		<>
			<Button
				className={`btn-fill ${className}`}
				onClick={() => {
					setOpenPrintDialog(true);
				}}
			>
				{label}
			</Button>

			{openPrintDialog && (
				<FormInPhieuDialog<T>
					show={openPrintDialog}
					onHide={() => {
						setOpenPrintDialog(false);
					}}
					fetchExport={fetchExport}
					params={params}
				/>
			)}
		</>
	);
};

export default ButtonIn;
