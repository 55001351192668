import React from 'react';
import { columnNamesType } from '../../../component/table/simple-table/SimpleTable';

type ColumnType = columnNamesType & {
	width: number;
	textAlign?: string;
};

export const COLUMNS: ColumnType[] = [
	{
		name: '',
		field: 'radio',
		width: 50,
		textAlign: 'center',
	},
	{
		name: 'Mã Thuốc',
		field: 'code',
		width: 100,
	},
	{
		name: 'Tên Thuốc',
		field: 'name',
		width: 200,
	},
	{
		name: 'Hàm Lượng',
		field: 'dosage',
		width: 150,
	},
	{
		name: 'Hoạt Chất',
		field: 'actIngName',
		width: 150,
	},
	{
		name: 'Đơn vị',
		field: 'unitofMeasureName',
		width: 120,
		textAlign: 'center',
	},
	{
		name: 'Khả dụng',
		field: 'iventory',
		width: 80,
		textAlign: 'right',
	},
	{
		name: 'Giá BHYT',
		field: 'BHYTPrice',
		width: 100,
		textAlign: 'right',
	},
	{
		name: 'Giá VP',
		field: 'hospitalFeePrice',
		width: 100,
		textAlign: 'right',
	},
	{
		name: 'Giá dịch vụ',
		field: 'requestedPrice',
		width: 100,
		textAlign: 'right',
	},
	{
		name: 'Loại Thuốc',
		field: 'drugTypeName',
		width: 100,
	},
];
