import { TableCustom } from '../../../../../component/table/table-custom/TableCustom';

type Props = {
	data: any[];
	columns: any;
};

const ListDonThuoc = ({ data, columns }: Props) => {
	return (
		<TableCustom<any>
			className="overflow-auto spaces h-calc-vh-540"
			data={data}
			columns={columns}
		/>
	);
};

export default ListDonThuoc;
