import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ButtonInPhieu from '../../../../../component/button-in-phieu';
import { ConfirmDialog } from '../../../../../component/ConfirmDialog';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import PageKeThuoc from '../../../../modals/modal-ke-thuoc/PageKeThuoc';
import { IInPhieuChiDinhThuocParams } from '../../../../models/ThuocModels';
import { deleteDonThuoc, printPhieuChiDinhThuoc } from '../../../../services/ThuocService';
import { useTabThuocContext } from '../../context';

const ThaoTacDonThuoc = () => {
	const { donThuocInfo, setDonThuocSelectedIndex } = useTabThuocContext();
	const { benhNhanInfo, setBenhNhanInfo } = useContext(PhanHeTiepDonContext);

	const [guiDonThuoc, setGuiDonThuoc] = useState<boolean>(false);
	const [openDonThuocDialog, setOpenDonThuocDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const { patient, visit } = benhNhanInfo?.thongTinKhamBenh || {};

	const onDeleteDonThuoc = async () => {
		try {
			const response = await deleteDonThuoc(donThuocInfo.id);
			if (CODE.SUCCESS === response.code) {
				toast.success(RESPONSE_MESSAGE.DELETE.SUCCESS);
				setOpenConfirmDialog(false);
				updatePatientInfo();
				setDonThuocSelectedIndex(0);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const updatePatientInfo = () => {
		setBenhNhanInfo({
			...benhNhanInfo,
			shouldUpdate: !benhNhanInfo.shouldUpdate,
		});
	};

	return (
		<div className="d-flex gap-3 justify-content-start p-2 bg-white">
			{!guiDonThuoc ? (
				<>
					<Button
						className="btn-navy-outlined mr-5"
						onClick={() => setOpenDonThuocDialog(true)}
					>
						Sửa đơn thuốc
					</Button>
					<Button
						className="btn-fill mr-5"
						onClick={() => {
							setGuiDonThuoc(!guiDonThuoc);
						}}
					>
						Gửi đơn thuốc
					</Button>
					<Button
						className="btn-secondary mr-5"
						onClick={() => setOpenConfirmDialog(true)}
					>
						Xóa đơn thuốc
					</Button>
				</>
			) : (
				<>
					<ButtonInPhieu<any>
						fetchExport={printPhieuChiDinhThuoc}
						params={{}}
						label="Phiếu truyền HC"
						className="btn-outlined"
					/>
					<ButtonInPhieu<IInPhieuChiDinhThuocParams>
						fetchExport={printPhieuChiDinhThuoc}
						params={{
							fileType: 'pdf',
							patientId: patient.id,
							medicalItemGroupId: donThuocInfo.id,
							visitId: visit.id,
							type: parseInt(ORDER_TYPE_ID.THUOC),
						}}
						label="Đơn thuốc"
						className="btn-fill"
					/>
					<Button
						className="btn-secondary"
						onClick={() => {
							setGuiDonThuoc(!guiDonThuoc);
						}}
					>
						Hủy gửi đơn thuốc
					</Button>
				</>
			)}

			{openDonThuocDialog && (
				<PageKeThuoc
					open={openDonThuocDialog}
					handleClose={() => {
						setOpenDonThuocDialog(false);
					}}
					itemEdit={donThuocInfo}
					onSave={() => {
						setOpenDonThuocDialog(false);
						updatePatientInfo();
					}}
				/>
			)}

			{openConfirmDialog && (
				<ConfirmDialog
					className="z-index-1060"
					show={openConfirmDialog}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa đơn thuốc này không ?"
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setOpenConfirmDialog(false);
					}}
					onYesClick={onDeleteDonThuoc}
				/>
			)}
		</div>
	);
};

export default ThaoTacDonThuoc;
